import {rest} from 'msw'
import uniqBy from 'ramda/src/uniqBy'
import prop from 'ramda/src/prop'

import {
  userListsFactory,
  getLocalItems,
  getLocalLists,
  getFakeId,
} from './mockHelpers'
import {apiBaseUrl} from '../config'
import {Item, List} from '../types'

if (!getLocalLists().length) {
  window.localStorage.setItem(
    'lists',
    JSON.stringify(userListsFactory(getLocalItems())),
  )
}

const getUserListsHandler = rest.get<null, {}, List[]>(
  apiBaseUrl + '/lists',
  (_req, res, ctx) => {
    // return res(ctx.status(200), ctx.json([]), ctx.delay(600)) // empty
    // return res(ctx.status(400), ctx.delay(600)) // with error
    return res(ctx.status(200), ctx.json(getLocalLists()), ctx.delay(600))
  },
)

const createListHandler = rest.post<{name: string; items?: Item[]}, {}, List>(
  apiBaseUrl + '/lists',
  async (req, res, ctx) => {
    const body = await req.json()
    const newList: List = {
      id: getFakeId(),
      name: body.name,
      items: body.items || [],
      status: 'draft',
    }
    const updatedResponse = [...getLocalLists(), newList]
    window.localStorage.setItem('lists', JSON.stringify(updatedResponse))
    return res(ctx.status(200), ctx.json(newList), ctx.delay(600))
  },
)

const getListHandler = rest.get<null, {id: string}, List>(
  apiBaseUrl + '/lists/:id',
  (req, res, ctx) => {
    // @ts-ignore
    const list = getLocalLists().find(({id}) => id === req.params.id)
    // return res(ctx.status(200), ctx.json([]), ctx.delay(600)) // empty
    // return res(ctx.status(400), ctx.delay(600)) // with error
    if (!list) return res(ctx.status(404), ctx.delay(600))
    return res(ctx.status(200), ctx.json(list), ctx.delay(600))
  },
)

const deleteListHandler = rest.delete<null, {id: string}, List[]>(
  apiBaseUrl + '/lists/:id',
  (req, res, ctx) => {
    const updatedResponse = getLocalLists().filter(
      list => list.id !== req.params.id,
    )
    window.localStorage.setItem('lists', JSON.stringify(updatedResponse))
    return res(ctx.status(200), ctx.json(updatedResponse), ctx.delay(600))
  },
)

const updateListHandler = rest.patch<{name: string}, {id: string}, List>(
  apiBaseUrl + '/lists/:id',
  async (req, res, ctx) => {
    const body = await req.json()
    const updatedResponse = getLocalLists().map(list =>
      list.id === req.params.id ? {...list, ...body} : list,
    )
    window.localStorage.setItem('lists', JSON.stringify(updatedResponse))
    const list = updatedResponse.find(({id}) => id === req.params.id)
    if (!list) return res(ctx.status(404), ctx.delay(600))
    return res(ctx.status(200), ctx.json(list), ctx.delay(600))
  },
)

const addItemToListHandler = rest.put<null, {listId: string; itemId: string}>(
  apiBaseUrl + '/lists/:listId/items/:itemId',
  async (req, res, ctx) => {
    const item = getLocalItems().find(({id}) => id === req.params.itemId)
    if (!item) return res(ctx.status(404), ctx.delay(600))
    const updatedResponse = getLocalLists().map(list =>
      list.id === req.params.listId
        ? {...list, items: uniqBy(prop('id'), [...list.items, item])}
        : list,
    )
    window.localStorage.setItem('lists', JSON.stringify(updatedResponse))
    const list = updatedResponse.find(({id}) => id === req.params.listId)
    if (!list) return res(ctx.status(404), ctx.delay(600))
    // return res(ctx.status(400), ctx.delay(600)) // with error
    return res(ctx.status(200), ctx.json(list), ctx.delay(600))
  },
)

const removeItemFromListHandler = rest.delete<
  null,
  {listId: string; itemId: string}
>(apiBaseUrl + '/lists/:listId/items/:itemId', async (req, res, ctx) => {
  const updatedResponse = getLocalLists().map(list =>
    list.id === req.params.listId
      ? {
          ...list,
          items: list.items.filter(item => item.id !== req.params.itemId),
        }
      : list,
  )
  window.localStorage.setItem('lists', JSON.stringify(updatedResponse))
  const list = updatedResponse.find(({id}) => id === req.params.listId)
  if (!list) return res(ctx.status(404), ctx.delay(600))
  return res(ctx.status(200), ctx.json(list), ctx.delay(600))
})

const sendListHandler = rest.post<{message?: string}, {listId: string}, List>(
  apiBaseUrl + '/lists/review/:listId',
  async (req, res, ctx) => {
    const body = await req.json()
    const updatedResponse = getLocalLists().map(list =>
      list.id === req.params.listId
        ? {...list, status: 'pending approval' as const}
        : list,
    )
    window.localStorage.setItem('lists', JSON.stringify(updatedResponse))
    const list = updatedResponse.find(({id}) => id === body.listId)
    if (!list) return res(ctx.status(404), ctx.delay(600))
    return res(ctx.status(200), ctx.json(list), ctx.delay(600))
  },
)

export const handlers = [
  removeItemFromListHandler,
  addItemToListHandler,
  getUserListsHandler,
  createListHandler,
  deleteListHandler,
  updateListHandler,
  sendListHandler,
  getListHandler,
]
