export const GENERIC_ERROR_MESSAGE =
  'Something failed. Please, try again later.'

export const SESSION_EXPIRED_ERROR_MESSAGE =
  'Your session expired. Please, login again and try again later.'

export const removeGrade = () => {
  try {
    const userString = window.localStorage.getItem('user')
    const user = userString ? JSON.parse(userString) : null
    if (user) {
      if (user.selectedGrade) {
        delete user.selectedGrade
      }
      window.localStorage.setItem('user', user)
    }
  } catch (error) {
    console.warn(error)
  }
}

export const removeSession = () => {
  window.localStorage.removeItem('token')
  window.localStorage.removeItem('refreshToken')
  window.localStorage.removeItem('expires')
  window.localStorage.removeItem('user')
}
