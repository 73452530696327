import {GENERIC_ERROR_MESSAGE, SESSION_EXPIRED_ERROR_MESSAGE} from './common'
import {School} from '../types'
import {apiBaseUrl} from '../config'
import authorizationHeader from './authorizationHeader'

export async function getSchool(schoolName: string = 'me'): Promise<School> {
  return fetch(apiBaseUrl + `/schools/${schoolName}`, {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) {
      throw new Error(
        res.status === 401
          ? SESSION_EXPIRED_ERROR_MESSAGE
          : GENERIC_ERROR_MESSAGE,
      )
    }
    return res.json()
  })
}
