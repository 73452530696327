import {useCallback, useEffect, useState} from 'react'
import {apiBaseUrl} from '../config'
import {GENERIC_ERROR_MESSAGE} from '../api/common'

type StockAmount = {
  text: string
  minAmount: number
  backgroundColor: string
  textColor: string
}

type SiteConfiguration = {
  instructivePdfUrl: string
  stockAmounts?: StockAmount[]
}

function useSiteConfiguration() {
  const [config, setConfig] = useState<SiteConfiguration>({
    instructivePdfUrl: '',
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function init() {
      try {
        const res = await fetchSiteConfiguration()
        setConfig(res)
      } catch (error) {
        console.error(error)
        setConfig({instructivePdfUrl: ''})
      }
      setLoading(false)
    }

    init()
  }, [])

  const getStockText = useCallback(
    (stock?: number | string | null) => {
      if (
        typeof stock !== 'undefined' &&
        stock !== null &&
        !isNaN(Number(stock))
      ) {
        const stockFound = (config.stockAmounts || []).reduce(
          (found, current) => {
            if (
              current.minAmount <= Number(stock) &&
              (!found || current.minAmount > found.minAmount)
            ) {
              return current
            }
            return found
          },
          null as StockAmount | null,
        )
        if (stockFound) {
          return stockFound
        }
      }
      return null
    },
    [config.stockAmounts],
  )

  return {
    config,
    loading,
    getStockText,
  }
}

export default useSiteConfiguration

export async function fetchSiteConfiguration(): Promise<SiteConfiguration> {
  return fetch(apiBaseUrl + '/config').then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}
