import {classNames} from '../utils'

export default function Spinner({
  className = '',
  reverse = false,
  size = 'large',
  color,
}: {
  className?: string
  reverse?: boolean
  color?: string
  size?: 'small' | 'large'
}) {
  return (
    <svg
      className={classNames(
        color || (reverse ? 'text-white' : 'text-blue-500'),
        size === 'small' ? 'h-4 w-4' : 'h-8 w-8',
        'animate-spin',
        className,
      )}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none">
      <circle
        strokeWidth="4"
        className="opacity-25"
        stroke="currentColor"
        cx="12"
        cy="12"
        r="10"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
}
