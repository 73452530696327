import useSWR from 'swr'

import {School} from '../types'
import {getSchool} from '../api/schoolApi'
import {userFetcher} from './useUser'

export default function useSchool(schoolHubspotId?: string) {
  const response = useSWR<School, Error>('school', async () => {
    const user = await userFetcher()
    if (!user) {
      throw new Error('user must be logged')
    }
    return getSchool(user.type === 'admin' ? schoolHubspotId || 'me' : 'me')
  })

  return response
}
