import {
  ExclamationCircleIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/24/outline'
import {useSWRConfig} from 'swr'

import uniqBy from 'ramda/src/uniqBy'
import prop from 'ramda/src/prop'

import BaseDialog, {Props as BaseDialogProps} from './BaseDialog'
import {addItemToList, removeItemFromList} from '../api/listApi'
import CreateListButton from './CreateListButton'
import {classNames} from '../utils'
import {Item, List} from '../types'
import {useToasts} from './ToastsProvider'
import useUserLists from '../hooks/useUserLists'
import Spinner from './Spinner'

interface Props {
  onSubmit(): void
  onClose: BaseDialogProps['onClose']
  isOpen: BaseDialogProps['isOpen']
  item: Item
}

export default function AddToListDialog(props: Props) {
  const {addToast} = useToasts()
  const {data, error, mutate} = useUserLists()
  const global = useSWRConfig()

  const isLoading = !data && !error

  const handleRemoveItemFromList = (listId: List['id']) => {
    mutate(
      () =>
        removeItemFromList(props.item.id, listId).then(res => {
          addToast(`${props.item.title} removed from list`, {
            variant: 'success',
          })
          global.mutate(['list', listId], res)
          props.onClose()
          return undefined
        }),
      {
        rollbackOnError: true,
        optimisticData(currentData = []) {
          return currentData.map(currentList =>
            listId === currentList.id
              ? {
                  ...currentList,
                  items: currentList.items.filter(
                    item => item.id !== props.item.id,
                  ),
                }
              : currentList,
          )
        },
        // removeItemFromList returns the updated list
        // but we are mutating the whole user lists collection
        populateCache: false,
      },
    )
  }

  const handleAddItemToList = (listId: List['id']) => {
    mutate(
      () =>
        addItemToList(props.item.id, listId).then(res => {
          addToast(`${props.item.title} added to list`, {
            variant: 'success',
          })
          global.mutate(['list', listId], res)
          props.onClose()
          return undefined
        }),
      {
        rollbackOnError: true,
        optimisticData(currentData = []) {
          return currentData.map(currentList =>
            listId === currentList.id
              ? {
                  ...currentList,
                  items: uniqBy(prop('id'), [...currentList.items, props.item]),
                }
              : currentList,
          )
        },
        // addItemToList returns the updated list
        // but we are mutating the whole user lists collection
        populateCache: false,
      },
    )
  }

  return (
    <BaseDialog
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Add to lists"
      size="sm">
      {data && (
        <ul className="mt-4 -mr-4 max-h-[50vh] -space-y-1 overflow-y-scroll pr-6">
          {data.map(list => {
            const defaultChecked = list.items.some(
              item => item.id === props.item.id,
            )
            const canSelect =
              list.status === 'draft' || list.status === 'pending approval'
            return (
              <li key={list.id}>
                <label
                  className={classNames(
                    canSelect
                      ? 'cursor-pointer hover:bg-blue-50'
                      : 'cursor-not-allowed',
                    '-mx-2 flex items-baseline gap-2 rounded-md px-2 py-2',
                  )}>
                  <input
                    defaultChecked={defaultChecked}
                    className="inline-block h-4 w-4 self-center text-blue-500 accent-current outline-none ring-offset-2 focus-visible:ring-2"
                    onChange={event =>
                      event.target.checked
                        ? handleAddItemToList(list.id)
                        : handleRemoveItemFromList(list.id)
                    }
                    disabled={!canSelect}
                    value={list.id}
                    type="checkbox"
                  />
                  <span
                    className={classNames(
                      !canSelect && 'text-slate-400',
                      'grow',
                    )}>
                    {list.name}
                  </span>
                  {list.status !== 'draft' && (
                    <PaperAirplaneIcon className="w-4 shrink-0 -rotate-45 text-slate-300" />
                  )}
                </label>
              </li>
            )
          })}
        </ul>
      )}

      <CreateListButton item={props.item} onClose={props.onClose} />

      {isLoading && <Spinner className="mx-auto my-12" />}

      {error && !data && (
        <div className="mx-auto my-12 text-center">
          <ExclamationCircleIcon className="inline-block w-8 text-red-400" />
          <div className="mt-2 text-sm text-slate-500">{error.message}</div>
        </div>
      )}
    </BaseDialog>
  )
}
