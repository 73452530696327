import {BuildingOfficeIcon, SparklesIcon} from '@heroicons/react/24/outline'
import * as React from 'react'
import {Link} from 'react-router-dom'

import {classNames} from '../utils'
import {ListItem as ListItemType} from '../types'
import useSiteConfiguration from '../hooks/useSiteConfiguration'

interface Props {
  toolbar?: React.ReactNode
  item: ListItemType
  listId?: string
  onAmountChange?: (item: ListItemType) => void
  previousItem?: ListItemType | null
}

export default function ListItem({
  item,
  toolbar,
  listId,
  onAmountChange,
  previousItem,
}: Props) {
  const {getStockText} = useSiteConfiguration()

  const stock = getStockText(item.stock)

  return (
    <div className="group relative flex flex-col-reverse md:flex-row">
      <div className="relative ml-4 w-1/4 max-w-[120px] md:ml-0">
        <div className="relative">
          <img
            className="block bg-slate-200 shadow-md"
            src={item.thumbnail || '/Image_not_available.png'}
            alt=""
          />

          {item.isRecommended && (
            <div className="absolute bottom-2 -right-2 rounded-sm rounded-tr-none bg-green-600 px-2 text-xs text-white shadow-lg [text-shadow:1px_1px_0_gray]">
              Recommended
              <svg
                className="absolute bottom-[100%] right-0 fill-green-700"
                viewBox="0 0 8 5"
                height="5"
                width="8">
                <polygon points="0,0 8,5 0,5" />
              </svg>
            </div>
          )}
        </div>
      </div>

      <div className="ml-4">
        <div className="flex flex-col items-baseline gap-4 md:flex-row">
          <Link
            className="ring-offset-3 rounded-md text-2xl text-blue-900 decoration-slate-200 decoration-2 underline-offset-2 outline-none before:absolute before:inset-0 before:content-[''] focus-visible:ring-2 group-hover:underline"
            to={'/item/' + item.id}>
            {item.title}
          </Link>

          {item.isFeatured && (
            <span className="relative -top-0.5 rounded-md border border-amber-500 px-1 py-0.5 font-semibold uppercase text-amber-500 [font-size:11px] [letter-spacing:1px]">
              <SparklesIcon className="inline-block w-4 text-amber-500" />{' '}
              Featured
            </span>
          )}
        </div>

        <p className="text-sm text-slate-500">by {item.author}</p>

        <div className="mt-3">
          <p className="text-sm text-slate-600">
            <span
              className={classNames(
                item.component === "Student's" &&
                  'border-teal-500 text-teal-500',
                item.component === 'Workbook' &&
                  'border-amber-500 text-amber-500',
                item.component === "Teacher's" &&
                  'border-rose-500 text-rose-500',
                'rounded-md  bg-transparent py-0.5 font-bold uppercase [font-size:11px] [letter-spacing:1px]',
              )}>
              {item.component}
            </span>{' '}
            <span className="mx-1 text-slate-400">•</span>{' '}
            <BuildingOfficeIcon className="relative -top-[1.5px] inline-block w-4 align-middle text-slate-400" />{' '}
            {item.editorial} <span className="mx-1 text-slate-400">•</span>{' '}
            {item.edition} <span className="mx-1 text-slate-400">•</span>{' '}
            <span className="font-semibold text-green-600">
              {item.price === 1 ? '$' : item.price === 2 ? '$$' : '$$$'}
            </span>{' '}
          </p>
        </div>

        <p className="mt-3 max-w-prose">{item.description}</p>

        <div className="mt-3">
          {(item.labels || []).map(label => {
            return (
              <span
                key={`label-${label}`}
                className="relative -top-0.5 mr-1 rounded-md border border-orange-400 px-1 py-0.5 font-semibold uppercase text-orange-400 [font-size:11px] [letter-spacing:1px]">
                {label}
              </span>
            )
          })}
          {stock && stock.text && (
            <span
              style={{
                backgroundColor: stock.backgroundColor,
                borderColor: stock.backgroundColor,
                color: stock.textColor,
              }}
              className={`relative -top-0.5 mr-1 rounded-md border px-1 py-0.5 font-semibold uppercase [font-size:11px] [letter-spacing:1px]`}>
              {stock.text}
            </span>
          )}
        </div>

        {!!onAmountChange && !!listId && typeof item.amount !== 'undefined' && (
          <div className="mt-3">
            <span className="relative -top-0.5 mr-1 rounded-md border border-blue-400 px-1 py-0.5 font-semibold uppercase text-blue-400 [font-size:11px] [letter-spacing:1px]">
              {previousItem && previousItem.amount !== item.amount && (
                <span className="mr-1 line-through">
                  {previousItem?.amount}
                </span>
              )}
              {item.amount} {item.amount === 1 ? 'book' : 'books'}
            </span>
          </div>
        )}

        {toolbar && (
          <div className="absolute top-0 right-0 z-10 shrink-0">{toolbar}</div>
        )}
      </div>
    </div>
  )
}
