import * as React from 'react'

import { classNames } from '../utils'
import Spinner from './Spinner'

export interface Options {
  /**
   * Close the toast automatically after a certain amount of time (in ms).
   * Set to `0` to disable auto close, but you will need to call the `remove()`
   * prop or the `removeToast()` hook method to programatically close it
   * yourself.
   */
  autoCloseDelay?: number
  /**
   * Optionally display a spinner next to the message.
   */
  withSpinner?: boolean
  /**
   * Optionally render a button with a custom label and handler.
   */
  action?: { label: string; handler(args: Props): void } | null
  /**
   * You can use your own React component for a toast. It will receive these
   * same props.
   */
  component?: React.ComponentType<Props>
  /**
   * The design variant for the toast. If you provide a custom component,
   * honoring the `variant` prop would be up to you.
   */
  variant?: 'warning' | 'danger' | 'info' | 'success'
  /**
   * Toasts are bottom-center aligned by default but you can set each one
   * to be left or right aligned. Using this together with a custom component
   * would allow you to implement special toasts.
   */
  align?: 'left' | 'center' | 'right'
  /**
   * If you provide a custom `id` value for a specific toast you would be able
   * to use that `id` to programatically close the toast from any other
   * component.
   */
  id?: string
}

export function createToast(
  message: Toast['message'],
  options?: Options
): Toast {
  return {
    message,
    id: options?.id ?? Date.now().toString(),
    align: options?.align ?? 'center',
    action: options?.action ?? null,
    variant: options?.variant ?? 'info',
    component: options?.component ?? ToastComponent,
    withSpinner: options?.withSpinner ?? false,
    autoCloseDelay: options?.autoCloseDelay ?? 3000,
  }
}

export interface Toast extends Required<Options> {
  /**
   * Required message to display in the toast.
   */
  message: React.ReactNode
}

export interface Props extends Toast {
  /**
   * A handler to imperativelly close the toast. You can use it together
   * with the `action` prop or inside a custom `component`.
   */
  remove(): void
}

/**
 * Default toast component.
 *
 * Supports multiple variants, optional spinner and an optional action button.
 */
export const ToastComponent = (props: Props) => {
  return (
    <div
      className={classNames(
        'flex max-w-lg items-baseline gap-2 rounded-lg px-6 py-4 shadow-lg sm:py-3',
        props.variant === 'success' && 'bg-teal-500 text-teal-50',
        props.variant === 'warning' && 'bg-amber-300 text-amber-900',
        props.variant === 'danger' && 'bg-rose-600 text-rose-50',
        props.variant === 'info' && 'bg-slate-100 text-slate-600'
      )}
    >
      {props.withSpinner && (
        <div className="relative top-0.5 -ml-2">
          <Spinner
            color={
              {
                warning: 'text-amber-700',
                danger: 'text-rose-50',
                success: 'text-teal-50',
                info: 'text-slate-500',
              }[props.variant]
            }
            size="small"
          />
        </div>
      )}

      <div className="text-base">{props.message}</div>

      {props.action && (
        <button
          className={classNames(
            '-mr-3 rounded-md bg-transparent px-2 py-1 text-sm font-semibold underline underline-offset-2 outline-offset-4 focus-visible:outline-1 focus-visible:ring-0',
            props.variant === 'success' &&
              'text-teal-100 decoration-teal-400 hover:text-white hover:decoration-teal-300',
            props.variant === 'warning' &&
              'text-amber-700 decoration-amber-500 hover:text-amber-900',
            props.variant === 'danger' &&
              'text-rose-100 decoration-rose-400 hover:text-white hover:decoration-rose-200',
            props.variant === 'info' &&
              'text-slate-500 decoration-slate-300 hover:text-slate-700 hover:decoration-slate-400'
          )}
          onClick={() => props.action?.handler(props)}
          type="button"
        >
          {props.action.label}
        </button>
      )}
    </div>
  )
}
