import {ExclamationCircleIcon} from '@heroicons/react/24/outline'

import {Link} from 'react-router-dom'

import {Item} from '../types'
import useRelatedLevels from '../hooks/useRelatedLevels'
import {classNames} from '../utils'
import {cefrLevels} from 'teachers-types'

interface Props {
  currentLevel: string
  itemId: Item['id']
  uniqueLevel?: boolean
}

export default function RelatedLevelsSection(props: Props) {
  const {data, error} = useRelatedLevels(props.itemId)
  const isLoading = !data && !error

  if (props.uniqueLevel) {
    return (
      <div>
        <div className="mt-6 text-xs text-slate-500">CEFR level</div>

        <div className="mt-1 flex flex-wrap items-baseline">
          <div
            className={classNames(
              'z-10 cursor-auto border-blue-600 bg-blue-600 text-white',
              'border px-3 py-1 font-semibold uppercase [font-size:10px] [letter-spacing:1.5px] first:rounded-bl-md first:rounded-tl-md last:rounded-br-md last:rounded-tr-md',
            )}>
            {props.currentLevel}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="mt-6 text-xs text-slate-500">CEFR level</div>

      <div className="mt-1 flex flex-wrap items-baseline">
        {cefrLevels.map(level => {
          return level === props.currentLevel || !data?.[level] ? (
            <div
              className={classNames(
                isLoading && 'animate-pulse',
                level === props.currentLevel
                  ? 'z-10 cursor-auto border-blue-600 bg-blue-600 text-white'
                  : 'cursor-not-allowed border-slate-300 bg-slate-200 text-slate-500 opacity-30',
                'border px-3 py-1 font-semibold uppercase [font-size:10px] [letter-spacing:1.5px] first:rounded-bl-md first:rounded-tl-md last:rounded-br-md last:rounded-tr-md',
              )}
              key={level}>
              {level}
            </div>
          ) : (
            <Link
              className="border border-slate-200 px-3 py-1 font-semibold uppercase text-slate-500 [font-size:10px] [letter-spacing:1.5px] first:rounded-bl-md first:rounded-tl-md last:rounded-br-md last:rounded-tr-md hover:z-10 hover:border-blue-600 hover:text-blue-600"
              key={level}
              to={'/item/' + data[level]?.id}>
              {level}
            </Link>
          )
        })}
      </div>

      {!data && error && (
        <p className="mt-1 flex h-[25px] items-center gap-1 text-sm text-red-600">
          <ExclamationCircleIcon className="w-5" /> {error.message}
        </p>
      )}
    </div>
  )
}
