import {GENERIC_ERROR_MESSAGE} from './common'
import {apiBaseUrl} from '../config'
import {SearchLevel, User} from '../types'
import authorizationHeader from './authorizationHeader'

export async function getAccessCode(email: string): Promise<boolean> {
  try {
    const res = await fetch(apiBaseUrl + '/auth/access-code', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    })
    if (res.ok) {
      const data = await res.json()
      if (data && data.success) {
        return true
      }
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export async function sendAccessCode(email: string, code: string) {
  try {
    const res = await fetch(apiBaseUrl + '/auth/access-code/confirm', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email, code}),
    })
    if (res.ok) {
      const data = await res.json()
      if (data && data.token) {
        return data.token
      }
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export async function setSelectedLevel(grade: SearchLevel): Promise<boolean> {
  try {
    const res = await fetch(apiBaseUrl + '/teachers/selectedGrade', {
      headers: authorizationHeader({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      method: 'PATCH',
      body: JSON.stringify({grade}),
    })
    if (res.ok) {
      const data = await res.json()
      if (data && data.selectedGrade === grade) {
        return true
      }
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export async function getAccessToken(inviteToken: string): Promise<{
  token: string
  refreshToken: string
  expires: number
  user: User
}> {
  return fetch(apiBaseUrl + '/auth/access/confirm', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({token: inviteToken}),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function refreshAccessToken(): Promise<{
  token: string
  refreshToken: string
  expires: number
  user: User
} | null> {
  try {
    const refreshToken = window.localStorage.getItem('refreshToken')
    if (!refreshToken) {
      throw new Error('no refresh token stored')
    }
    const res = await fetch(apiBaseUrl + '/auth/refresh', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({refreshToken}),
    })
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  } catch (error) {
    console.warn(error)
    return null
  }
}
