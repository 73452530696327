import {useCallback} from 'react'
import {removeSession} from '../api/common'
import useUser from './useUser'

function useLogout() {
  const user = useUser()

  const logout = useCallback(() => {
    // when logging out, we clear the browser token
    removeSession()
    // also clear the swr cache (this will show the login page)
    user.mutate()
    setTimeout(() => (window.location.href = '/'))
  }, [user])

  return logout
}

export default useLogout
