import {faker} from '@faker-js/faker'
import {rest} from 'msw'

import {Component, CefrLevel, cefrLevels} from 'teachers-types'
import {getLocalItems} from './mockHelpers'
import {apiBaseUrl} from '../config'
import {Item} from '../types'

const getItemHandler = rest.get<null, {id: Item['id']}, Item>(
  apiBaseUrl + '/items/:id',
  (req, res, ctx) => {
    const item = getLocalItems().find(({id}) => id === req.params.id)
    if (!item) return res(ctx.status(404), ctx.delay(600))
    return res(ctx.status(200), ctx.json(item), ctx.delay(600))
  },
)

const getRelatedComponentsHandler = rest.get<
  null,
  {id: Item['id']},
  Partial<Record<Component, Item>>
>(apiBaseUrl + '/related-components/:id', (req, res, ctx) => {
  const items = getLocalItems()

  const item = items.find(({id}) => id === req.params.id)

  if (!item) return res(ctx.status(404), ctx.delay(600))

  const studentsItem = items.find(
    i => i.component === "Student's" && i.cefrLevel === item.cefrLevel,
  )
  const teachersItem = items.find(
    i => i.component === "Teacher's" && i.cefrLevel === item.cefrLevel,
  )
  const workbookItem = items.find(
    i => i.component === 'Workbook' && i.cefrLevel === item.cefrLevel,
  )

  return res(
    ctx.status(200),
    ctx.json({
      "Teacher's": item.component === "Teacher's" ? item : teachersItem,
      "Student's": item.component === "Student's" ? item : studentsItem,
      Workbook: item.component === 'Workbook' ? item : workbookItem,
    }),
    ctx.delay(600),
  )
})

const getRelatedLevelsHandler = rest.get<
  null,
  {id: Item['id']},
  Partial<Record<CefrLevel, Item>>
>(apiBaseUrl + '/related-levels/:id', (req, res, ctx) => {
  const items = getLocalItems()

  const item = items.find(({id}) => id === req.params.id)

  if (!item) return res(ctx.status(404), ctx.delay(600))

  const response = cefrLevels.reduce((acc, level) => {
    acc[level] =
      item.cefrLevel === level
        ? item
        : items.find(
            i => i.cefrLevel === level && i.component === item.component,
          )
    return acc
  }, {} as Partial<Record<CefrLevel, Item>>)

  return res(ctx.status(200), ctx.json(response), ctx.delay(600))
})

const getRelatedItemsHandler = rest.get<null, {id: Item['id']}, Item[]>(
  apiBaseUrl + '/related-items/:id',
  (_req, res, ctx) => {
    const randomItems = faker.helpers.arrayElements(
      getLocalItems(),
      faker.datatype.number({min: 1, max: 5}),
    )
    return res(ctx.status(200), ctx.json(randomItems), ctx.delay(600))
  },
)

export const handlers = [
  getRelatedComponentsHandler,
  getRelatedLevelsHandler,
  getRelatedItemsHandler,
  getItemHandler,
]
