import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getRelatedItems } from '../api/itemApi'
import { Item } from '../types'

export default function useRelatedItems(
  id: Item['id'],
  options?: SWRConfiguration
) {
  return useSWR<Item[], Error>(
    ['related-items', id],
    () => getRelatedItems(id),
    options
  )
}
