import {ClipboardEvent, useEffect, useState} from 'react'
import {sendAccessCode} from '../api/authApi'
import {SubmitHandler, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

type SubmitData = {
  code: string
}

export default function ConfirmPage() {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const existEmail = localStorage.getItem('userEmail')

    if (existEmail && existEmail !== null) {
      setEmail(existEmail)
    } else {
      navigate('/login')
    }
  }, [email, navigate])

  const {handleSubmit, register, setValue} = useForm<SubmitData>({
    shouldUnregister: true,
  })

  const onSubmit: SubmitHandler<SubmitData> = async ({code}) => {
    setLoading(true)
    const token = await sendAccessCode(email, code)
    if (token) {
      setSuccess(true)
      setError(false)
      localStorage.removeItem('userEmail')
      navigate(`/for-you/?token=${token}`)
    } else {
      setSuccess(false)
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    }
    setLoading(false)
  }

  const handlePaste = async (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    const pasteData = e.clipboardData.getData('Text').trim()

    if (pasteData.length === 6 && /^\d+$/.test(pasteData)) {
      setValue('code', pasteData)

      await onSubmit({code: pasteData})
    } else {
      setValue('code', '')
    }
  }

  return (
    <div className="container mx-auto my-20 max-w-xl px-4">
      <h1 className="mb-4 text-3xl">Verify your email address</h1>

      <p>
        We emailed you to a six-digit code to {email}. Enter the code below to
        confirm your email
      </p>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <input
          className="block w-1/4 min-w-fit border-b-2 text-lg outline-none focus:border-blue-700"
          {...register('code', {
            setValueAs: value => value.trim(),
            required: 'Required',
            maxLength: 6,
          })}
          onPaste={handlePaste}
          disabled={loading || success}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          maxLength={6}
        />
        {error && (
          <div className="mt-1 h-5">
            <p className="text-xs text-red-600">Expired or invalid code</p>
          </div>
        )}
        {!success && (
          <div className="mt-3 flex gap-4">
            <button
              disabled={loading}
              className="flex items-center gap-2 rounded-md bg-blue-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-blue-400 ring-offset-2 hover:bg-blue-600 focus-visible:ring-2 disabled:bg-blue-300"
              type="submit">
              Send
            </button>
          </div>
        )}
      </form>
    </div>
  )
}
