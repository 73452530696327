import {createSearchParams} from 'react-router-dom'

import {School, SearchParams, SearchResponse} from '../types'
import {GENERIC_ERROR_MESSAGE} from './common'
import {apiBaseUrl} from '../config'
import authorizationHeader from './authorizationHeader'
import {
  SCHOOL_SEVEN_GRADES_5_YEARS_PROVINCES,
  SCHOOL_SIX_GRADES_SIX_YEARS_PROVINCES,
} from 'teachers-types'

type Level = SearchParams['filters']['level'][number]

export async function getSearchResults(
  params: SearchParams,
  school?: School,
): Promise<SearchResponse> {
  const {filters: _filters, ...other} = params
  const filters: SearchParams['filters'] = {
    ..._filters,
    level: _filters.level
      .filter(level => isValidGrade(level, school?.province))
      .map(level => mapLevel(level, school)),
  }
  const urlParams = createSearchParams({
    filters: JSON.stringify(filters),
    ...other,
  })
  return fetch(apiBaseUrl + '/search?' + urlParams.toString(), {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function getForYouResults(
  params: Pick<SearchParams, 'sort'>,
): Promise<SearchResponse> {
  const urlParams = createSearchParams(params)
  return fetch(apiBaseUrl + '/search/for-you?' + urlParams.toString(), {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

function mapLevel(level: Level, school?: School) {
  // If school is 7/5, we need to add one year to every level from seventh grade
  if (!!school?.province && isSevenAndFiveSchool(school?.province)) {
    return addOneYear(level)
  }
  return level
}

/**
 * Returns a boolean indicating if the received grade must be used by user or not
 */
export function isValidGrade(grade: string, province?: string) {
  const isSeventhGrade = grade.toLocaleLowerCase().includes('séptimo grado')
  const isSixthYear = grade.toLocaleLowerCase().includes('sexto año')

  const valid =
    (!isSeventhGrade && !isSixthYear) ||
    (isSeventhGrade && isSevenAndFiveSchool(province)) ||
    (isSixthYear && isSixAndSixSchool(province))

  return !!valid
}

/**
 * Returns true if the school format is seven grades and five years depending the province where the school is located
 */
function isSevenAndFiveSchool(province?: string) {
  return (
    typeof province !== 'string' ||
    (SCHOOL_SEVEN_GRADES_5_YEARS_PROVINCES as any as string[]).includes(
      province,
    )
  )
}

/**
 * Returns true if the school format is six grades and six years depending the province where the school is located
 */
function isSixAndSixSchool(province?: string) {
  return (
    typeof province !== 'string' ||
    (SCHOOL_SIX_GRADES_SIX_YEARS_PROVINCES as any as string[]).includes(
      province,
    )
  )
}

function addOneYear(level: Level): Level {
  return incrementedLevels[level] || level
}

type IncrementedLevels = {
  // eslint-disable-next-line no-unused-vars
  [key in Level]?: Level
}

const incrementedLevels: IncrementedLevels = {
  'Primario|Séptimo Grado': 'Secundario|Primer Año',
  'Secundario|Primer Año': 'Secundario|Segundo Año',
  'Secundario|Segundo Año': 'Secundario|Tercer Año',
  'Secundario|Tercer Año': 'Secundario|Cuarto Año',
  'Secundario|Cuarto Año': 'Secundario|Quinto Año',
  'Secundario|Quinto Año': 'Secundario|Sexto Año',
}
