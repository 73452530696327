import {Outlet, useSearchParams} from 'react-router-dom'
import * as React from 'react'

import useUser from '../hooks/useUser'
import {getAccessToken, refreshAccessToken} from '../api/authApi'
import LoginPage from '../pages/LoginPage'
import LoadingPage from '../pages/LoadingPage'
import {useToasts} from './ToastsProvider'
import {getSchool} from '../api/schoolApi'
import {SESSION_EXPIRED_ERROR_MESSAGE, removeSession} from '../api/common'
import SelectGrade from '../pages/SelectGrade'
import useSchool from '../hooks/useSchool'
import useLogout from '../hooks/useLogout'

export default function Auth() {
  const [isPending, setIsPending] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const {data, mutate} = useUser()
  const {addToast} = useToasts()
  const schoolData = useSchool()
  const [panelError, setPanelError] = React.useState(false)

  const logout = useLogout()

  const inviteToken = searchParams.get('token')

  const refresh = React.useCallback(async () => {
    // Try to re-login with the refresh token
    const refreshData = await refreshAccessToken()
    if (!refreshData) {
      removeSession()
      logout()
    } else {
      const {token, expires, refreshToken, user} = refreshData
      // store in browser to persist session
      window.localStorage.setItem('token', token)
      window.localStorage.setItem('refreshToken', refreshToken)
      window.localStorage.setItem('expires', expires.toString())
      window.localStorage.setItem('user', JSON.stringify(user))
    }
    mutate()
  }, [logout, mutate])

  // Logout if session expired
  React.useEffect(() => {
    if (
      schoolData.error &&
      schoolData.error.message.includes('session expired')
    ) {
      refresh()
    }
  }, [schoolData, refresh])

  React.useEffect(() => {
    if (panelError) {
      addToast(
        'Warning... You are using a panel user. Please, try again with an email associated with a teacher.',
        {
          variant: 'danger',
          autoCloseDelay: 10000,
        },
      )
      addToast(
        'Advertencia... Estás usando un usuario de panel. Por favor, intentá nuevamente con un correo electrónico que esté asociado a un docente.',
        {
          variant: 'danger',
          autoCloseDelay: 10000,
        },
      )
      removeSession()
    }
  }, [addToast, panelError])

  React.useEffect(() => {
    async function checkToken() {
      try {
        // try to get school to see if token is still valid
        await getSchool('me')
      } catch (error) {
        if (((error || {}) as any)?.message === SESSION_EXPIRED_ERROR_MESSAGE) {
          refresh()
        }
      }
    }

    if (window.localStorage.getItem('token')) {
      checkToken()
    }
  }, [refresh])

  /**
   * If we have an invite token in the URL, fetch the new access token and user
   * data, store both in LocalStorage and display a spinner in the meantime.
   */
  React.useEffect(() => {
    if (!inviteToken) return
    setIsPending(true)
    setPanelError(false)
    getAccessToken(inviteToken)
      .then(({token, refreshToken, expires, user}) => {
        const panelTypes = ['admin' || 'panel']
        if (panelTypes.includes(user.type)) {
          setPanelError(true)
        } else {
          // store in browser to persist session
          window.localStorage.setItem('token', token)
          window.localStorage.setItem('refreshToken', refreshToken)
          window.localStorage.setItem('expires', expires.toString())
          window.localStorage.setItem('user', JSON.stringify(user))
          // update swr cache to hide login page
          mutate()
        }
      })
      .catch(() => {
        addToast('Oops... something went wrong. Please, try again.', {
          variant: 'danger',
        })
      })
      .finally(() => {
        searchParams.delete('token')
        setSearchParams(searchParams, {replace: true})
        setIsPending(false)
      })
  }, [inviteToken, searchParams, setSearchParams, mutate, addToast])

  if (isPending) return <LoadingPage />

  // if user is already logged in, render the inner route
  if (
    !!data &&
    (((!data.grades || data.grades.length === 0) && !schoolData.data) ||
      data.selectedGrade)
  ) {
    return <Outlet />
  }

  if (
    !!data &&
    !panelError &&
    ((data && data.grades && data.grades.length > 0) || schoolData.data) &&
    !data.selectedGrade
  ) {
    return <SelectGrade />
  }

  return <LoginPage />
}
