import {GENERIC_ERROR_MESSAGE} from './common'
import {apiBaseUrl} from '../config'
import {Item, ItemType} from '../types'
import authorizationHeader from './authorizationHeader'
import {CefrLevel, Component} from 'teachers-types'

export async function getItem(id: Item['id']): Promise<Item> {
  return fetch(apiBaseUrl + '/items/' + id, {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function getRelatedItems(id: Item['id']): Promise<Item[]> {
  return fetch(apiBaseUrl + '/related-items/' + id, {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function getRelatedComponents(
  id: Item['id'],
): Promise<Partial<Record<Component, Item>>> {
  return fetch(apiBaseUrl + '/related-components/' + id, {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function getRelatedLevels(
  id: Item['id'],
): Promise<Record<CefrLevel, Item>> {
  return fetch(apiBaseUrl + '/related-levels/' + id, {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function getItemTypes(): Promise<ItemType[]> {
  return fetch(apiBaseUrl + '/itemTypes', {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}
