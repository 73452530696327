import { setupWorker } from 'msw'

import { handlers as searchHandlers } from './searchHandlers'
import { handlers as listsHandlers } from './listsHandlers'
import { handlers as itemsHandlers } from './itemsHandlers'
import { handlers as adminHandlers } from './adminHandlers'
import { handlers as authHandlers } from './authHandlers'

// This configures a Service Worker with the given request handlers.
export const worker = setupWorker(
  ...searchHandlers,
  ...listsHandlers,
  ...itemsHandlers,
  ...adminHandlers,
  ...authHandlers
)
