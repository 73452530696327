import {ExclamationCircleIcon} from '@heroicons/react/24/outline'

import {Link} from 'react-router-dom'

import useRelatedItems from '../hooks/useRelatedItems'
import {Item} from '../types'
import Spinner from './Spinner'

interface Props {
  title: string
  itemId: Item['id']
}

export default function RelatedItemsSection(props: Props) {
  const {data, error} = useRelatedItems(props.itemId)
  const isLoading = !data && !error

  return (
    <section>
      <h2 className="text-sm uppercase text-slate-500">{props.title}</h2>

      {data && (
        <ul className="mt-3 grid grid-cols-[repeat(auto-fill,minmax(100px,1fr))] gap-6">
          {data.map(item => {
            return (
              <li className="group relative flex flex-col" key={item.id}>
                {!!item.thumbnail && (
                  <img
                    className="block max-w-[80px] bg-slate-200 shadow-md"
                    src={item.thumbnail || '/Image_not_available.png'}
                    alt=""
                  />
                )}
                <Link
                  className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2 underline-offset-2 before:absolute before:inset-0 before:content-[''] group-hover:underline"
                  to={'/item/' + item.id}>
                  {item.title}
                </Link>
              </li>
            )
          })}
        </ul>
      )}

      {isLoading && <Spinner className="mx-auto mt-4" />}

      {!data && error && (
        <div className="mx-auto my-24 text-center">
          <ExclamationCircleIcon className="inline-block w-12 text-red-400" />
          <div className="mt-2 text-slate-500">{error.message}</div>
        </div>
      )}
    </section>
  )
}
