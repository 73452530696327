import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { SearchParams, SearchResponse } from '../types'
import { getForYouResults } from '../api/searchApi'

export default function usePagedForYouResults(
  params: Pick<SearchParams, 'sort'>,
  options?: SWRConfiguration
) {
  return useSWR<SearchResponse, Error>(
    ['for-you-results', params],
    () => getForYouResults(params),
    options
  )
}
