import {ExclamationCircleIcon} from '@heroicons/react/24/outline'

import {Link} from 'react-router-dom'

import useRelatedComponents from '../hooks/useRelatedComponents'
import {Item} from '../types'
import {classNames} from '../utils'

interface Props {
  currentComponent: string
  itemId: Item['id']
}

export default function RelatedComponentsSection(props: Props) {
  const {data, error} = useRelatedComponents(props.itemId)
  const isLoading = !data && !error
  return (
    <div>
      <div className="mt-6 text-xs text-slate-500">Components</div>

      <div className="mt-1 md:flex">
        {props.currentComponent === "Student's" || !data?.["Student's"] ? (
          <div
            className={classNames(
              isLoading &&
                props.currentComponent !== "Student's" &&
                'animate-pulse',
              props.currentComponent === "Student's"
                ? 'z-10 border-teal-500 bg-teal-500 text-white [text-shadow:1px_1px_1px_gray]'
                : 'cursor-not-allowed border-slate-200 bg-slate-200 text-slate-500 opacity-30',
              'border px-3 py-1 font-semibold uppercase [font-size:10px] [letter-spacing:1.5px] md:rounded-bl-md md:rounded-tl-md',
            )}>
            Student&apos;s Book
          </div>
        ) : (
          <Link
            className="block border px-3 py-1 font-semibold uppercase text-slate-500 [font-size:10px] [letter-spacing:1.5px] hover:z-10 hover:border-teal-500 hover:text-teal-600 sm:mt-1 md:block md:rounded-tl-md md:rounded-bl-md"
            to={'/item/' + data["Student's"].id}>
            Student&apos;s Book
          </Link>
        )}

        {props.currentComponent === 'Workbook' || !data?.Workbook ? (
          <div
            className={classNames(
              isLoading &&
                props.currentComponent !== 'Workbook' &&
                'animate-pulse',
              props.currentComponent === 'Workbook'
                ? 'z-10 border-amber-500 bg-amber-500 text-white [text-shadow:1px_1px_1px_gray]'
                : 'cursor-not-allowed border-slate-200 text-slate-500 opacity-30',
              '-mx-[1px] border px-3 py-1 font-semibold uppercase [font-size:10px] [letter-spacing:1.5px]',
            )}>
            Workbook
          </div>
        ) : (
          <Link
            className="-mx-[1px] block border border-slate-200 px-3 py-1 font-semibold uppercase text-slate-500 [font-size:10px] [letter-spacing:1.5px] hover:z-10 hover:border-amber-500 hover:text-amber-600 sm:mt-1 md:block"
            to={'/item/' + data.Workbook.id}>
            Workbook
          </Link>
        )}

        {props.currentComponent === "Teacher's" || !data?.["Teacher's"] ? (
          <div
            className={classNames(
              isLoading &&
                props.currentComponent !== "Teacher's" &&
                'animate-pulse',
              props.currentComponent === "Teacher's"
                ? 'z-10 border-rose-500 bg-rose-500 text-white [text-shadow:1px_1px_1px_gray]'
                : 'cursor-not-allowed border-slate-200 bg-slate-200 text-slate-500 opacity-30',
              'border px-3 py-1 font-semibold uppercase [font-size:10px] [letter-spacing:1.5px] md:rounded-br-md md:rounded-tr-md',
            )}>
            Teacher&apos;s Book
          </div>
        ) : (
          <Link
            className="xs:mt-1 block border px-3 py-1 font-semibold uppercase text-slate-500 [font-size:10px] [letter-spacing:1.5px] hover:z-10 hover:border-rose-500 hover:text-rose-600 md:block md:rounded-tr-md md:rounded-br-md"
            to={'/item/' + data["Teacher's"].id}>
            Teacher&apos;s Book
          </Link>
        )}
      </div>

      {!data && error && (
        <p className="mt-1 h-[25px] items-center gap-1 text-sm text-red-600 md:flex">
          <ExclamationCircleIcon className="w-5" /> {error.message}
        </p>
      )}
    </div>
  )
}
