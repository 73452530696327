import type {SWRConfiguration} from 'swr'
import useSWR from 'swr'

import {User} from '../types'

export function userFetcher() {
  /**
   * If we have a user stored locally, assume its token is valid.
   * Optionally, we could validate the stored token here and clear the storage
   * if it's not and force a new login.
   */
  const stored = localStorage.getItem('user')
  return stored ? JSON.parse(stored) : null
}

export default function useUser(options?: SWRConfiguration) {
  return useSWR<User, Error>('user', userFetcher, options)
}
