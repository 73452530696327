import {SubmitHandler, useForm} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {useSWRConfig} from 'swr'
import * as React from 'react'

import BaseDialog, {Props as BaseDialogProps} from './BaseDialog'
import {updateItemListAmount} from '../api/listApi'
import {useToasts} from './ToastsProvider'
import {Item} from '../types'
import useList from '../hooks/useList'

import useItem from '../hooks/useItem'

type ListItem = Item & {
  amount: number
}

interface Props {
  onSubmit(message?: string): Promise<ListItem | undefined>
  onClose: BaseDialogProps['onClose']
  isOpen: BaseDialogProps['isOpen']
  listItemId: ListItem['id']
  listId: Item['id']
}

type FormData = {
  amount?: ListItem['amount']
}

export default function EditListItemDialog(props: Props) {
  const {addToast} = useToasts()
  const global = useSWRConfig()
  const {data: listData, mutate: mutateList} = useList(props.listId)
  const {data} = useItem(props.listItemId)

  const form = useForm<FormData>({shouldUnregister: true})

  const {
    handleSubmit,
    formState: {errors},
    setValue,
    register,
  } = form

  const currentAmount: number | undefined =
    listData?.amounts?.[props.listItemId] || listData?.studentsAmount

  const handleListUpdate: SubmitHandler<FormData> = ({amount}) => {
    if (!data) return
    props.onClose()
    mutateList(
      () =>
        updateItemListAmount(props.listId, props.listItemId, amount).then(
          res => {
            global.mutate('lists') // update name in sidebar
            addToast('List updated', {variant: 'success'})
            return res
          },
        ),
      {
        rollbackOnError: true,
      },
    )
  }

  // re-populate field value when editing starts
  // (needed for handling "cancel" correctly)
  React.useEffect(() => {
    if (typeof currentAmount !== 'undefined') {
      setValue('amount', currentAmount)
    }
  }, [currentAmount, setValue])

  return (
    <BaseDialog
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Edit item details"
      size="lg">
      <form className="mt-4 " onSubmit={handleSubmit(handleListUpdate)}>
        <div className="mt-0 grid grid-cols-2 gap-8">
          <div className="">
            <label className="text-sm text-slate-500" htmlFor="grade">
              Books amount
            </label>
            <input
              type="number"
              {...register('amount', {min: 0, required: 'Required'})}
              className="leading-2 block h-9 w-full border-b-2 border-slate-300 bg-transparent text-base outline-none focus:border-blue-700"
            />
            <div className="mt-1 h-5">
              <ErrorMessage
                className="text-xs text-red-600"
                errors={errors}
                name="amount"
                as="div"
              />
            </div>
          </div>
        </div>

        <div className="mt-3 flex gap-4">
          <button
            className="flex items-center gap-2 rounded-md bg-blue-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-blue-400 ring-offset-2 hover:bg-blue-600 focus-visible:ring-2 disabled:bg-blue-300"
            type="submit">
            Save
          </button>
          <button
            className="rounded-md px-4 py-3 text-sm font-semibold text-blue-500 underline outline-none ring-blue-400 ring-offset-2 hover:text-blue-600 focus-visible:ring-2 disabled:text-blue-300"
            onClick={props.onClose}
            type="button">
            Cancel
          </button>
        </div>
      </form>
    </BaseDialog>
  )
}
