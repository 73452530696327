import {rest} from 'msw'

import {adminUserFactory, teacherUserFactory} from './mockHelpers'
import {apiBaseUrl} from '../config'
import {SearchLevel, User} from '../types'

const getInviteHandler = rest.get<null, {email: string}, {success: boolean}>(
  apiBaseUrl + '/auth/access',
  async (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
      }),
      ctx.delay(600),
    )
  },
)

const getSetSelectedLevelHandler = rest.get<
  null,
  {grade: SearchLevel},
  {selectedGrade: SearchLevel}
>(apiBaseUrl + '/teachers/selectedGrade', async (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      selectedGrade: req.params.grade,
    }),
    ctx.delay(600),
  )
})

const getUserHandler = rest.get<
  null,
  {inviteToken: string},
  {token: string; user: User; expires: number; refreshToken: string}
>(apiBaseUrl + '/auth/access/confirm', async (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      expires: 999999999999999,
      token: 'fake-access-token',
      refreshToken: 'fake-access-refresh-token',
      user:
        req.params.inviteToken === 'admin-invite-token'
          ? adminUserFactory()
          : teacherUserFactory(),
    }),
    ctx.delay(600),
  )
})

export const handlers = [
  getInviteHandler,
  getUserHandler,
  getSetSelectedLevelHandler,
]
