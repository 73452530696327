import {SubmitHandler, useForm} from 'react-hook-form'
import {ErrorMessage} from '@hookform/error-message'
import {PlusIcon} from '@heroicons/react/24/outline'
import * as React from 'react'
import {Props as BaseDialogProps} from './BaseDialog'

import {createList} from '../api/listApi'
import {Item, List} from '../types'
import {useToasts} from './ToastsProvider'
import useUserLists from '../hooks/useUserLists'

interface Props {
  item: Item
  onClose: BaseDialogProps['onClose']
}

type FormData = {name: string}

export default function CreateListButton(props: Props) {
  const {addToast} = useToasts()
  const [isEditing, setIsEditing] = React.useState(false)
  const {mutate} = useUserLists()

  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm<FormData>({shouldUnregister: true})

  const handleListCreate: SubmitHandler<FormData> = ({name}) => {
    setIsEditing(false)
    const newList: List = {
      name,
      items: [props.item],
      id: 'optimistic',
      status: 'draft',
    }
    mutate(
      () =>
        createList(name, [props.item]).then(() => {
          addToast(`List "${name}" created`, {variant: 'success'})
          props.onClose()
          return undefined
        }),
      {
        rollbackOnError: true,
        optimisticData: (currentData = []) => [...currentData, newList],
        populateCache: false,
      },
    )
  }

  return (
    <div className="-mx-6 -mb-6 mt-4 bg-slate-100 px-6 py-6">
      {isEditing ? (
        <form
          className="flex items-center gap-2 "
          onSubmit={handleSubmit(handleListCreate)}>
          <div className="relative flex grow">
            <label className="absolute -translate-y-3 text-xs" htmlFor="name">
              List name
            </label>
            <input
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              className="leading-2 block h-9 w-full border-b-2 border-slate-300 bg-transparent text-base outline-none focus:border-blue-700"
              autoFocus
              type="text"
              {...register('name', {
                setValueAs: v => v.trim(),
                required: 'Required',
              })}
            />
            <ErrorMessage
              className="absolute translate-y-10 text-xs text-red-600"
              errors={errors}
              name="name"
              as="div"
            />
          </div>

          <button
            className="shrink-0 rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white outline-none ring-offset-2 focus-visible:ring-2"
            type="submit">
            Create
          </button>
          <button
            className="-mr-3 shrink-0 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-blue-500 outline-none hover:text-blue-700 focus-visible:ring-2"
            onClick={() => setIsEditing(false)}
            type="button">
            Cancel
          </button>
        </form>
      ) : (
        <button
          className="relative -left-[10px] flex items-center gap-1 rounded-md py-2 pl-2 pr-3 text-sm outline-none hover:text-blue-700 focus-visible:ring-2"
          onClick={() => setIsEditing(true)}
          type="button">
          <PlusIcon className="h-5 w-5" /> Create new list
        </button>
      )}
    </div>
  )
}
