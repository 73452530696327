import {
  ExclamationCircleIcon,
  HandThumbDownIcon,
} from '@heroicons/react/24/outline'
import {useSearchParams} from 'react-router-dom'
import * as React from 'react'

import {classNames} from '../utils'
import * as types from '../types'
import useForYou from '../hooks/useForYou'
import ListItem from '../components/ListItem'
import Spinner from '../components/Spinner'

type State = Pick<types.SearchParams, 'sort'>

type Action = {type: 'sort changed'; payload: State['sort']}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'sort changed': {
      return {...state, sort: action.payload}
    }
  }
}

function init(currentParams?: URLSearchParams): State {
  const params = currentParams || new URLSearchParams()
  return {
    sort: params.get('sort') || 'edition|desc',
  } as State
}

export default function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [state, dispatch] = React.useReducer(reducer, searchParams, init)

  // update the URL querystring each time the search state changes
  React.useEffect(() => {
    setSearchParams(state, {replace: true})
  }, [setSearchParams, state])

  const {data, isValidating, error} = useForYou(state, {
    // handy for keep displaying the filters while executing a new search
    keepPreviousData: true,
  })

  const isSearching = !data && !error

  return (
    <form className="mt-12 md:mt-0">
      <h1 className="-mt-2 mb-3 text-xl">
        Based on your profile, these are our recommendations for you
      </h1>

      <div className="flex items-start gap-10">
        <div className="max-w-4xl grow">
          <div className="flex items-baseline justify-between border-b pt-4 pb-2">
            {data ? (
              <p className="text-sm text-slate-500">
                {data.count} {data.count === 1 ? 'book' : 'books'}
              </p>
            ) : (
              <div className="inline-block h-4 w-20 animate-pulse self-center rounded-md bg-slate-200" />
            )}

            <label className="text-sm text-slate-500">
              Sort by
              <select
                className="ml-2 rounded-md py-1 font-semibold text-slate-500 outline-none ring-offset-1 focus-visible:ring-2"
                onChange={e =>
                  dispatch({
                    type: 'sort changed',
                    payload: e.target.value as State['sort'],
                  })
                }
                value={state.sort}>
                <option value="edition|desc">Newest edition</option>
                <option value="edition|asc">Oldest edition</option>
                <option value="price|desc">Highest price</option>
                <option value="price|asc">Lowest price</option>
                <option value="relevance|desc">Most relevant</option>
                <option value="relevance|asc">Least relevant</option>
              </select>
            </label>
          </div>

          {!!data && !!data.filters && (
            <div className="mt-2 flex flex-col justify-evenly gap-2">
              {Object.keys(data.filters).map(key => {
                return (
                  <div
                    className="mt-2 flex flex-row justify-start gap-2"
                    // @ts-ignore
                    key={`${data.filters[key]}-key`}>
                    <p className="text-sm font-semibold text-slate-500">
                      {/* @ts-ignore */}
                      {key}:
                    </p>
                    <p className="text-sm text-slate-500">
                      {/* @ts-ignore */}
                      {data.filters[key]}
                    </p>
                  </div>
                )
              })}
            </div>
          )}

          {data && data.data.length > 0 && (
            <ul
              className={classNames(isValidating && 'opacity-50', 'divide-y')}>
              {data.data.map(item => {
                return (
                  <li key={item.id} className="py-6 px-0">
                    <ListItem item={item} />
                  </li>
                )
              })}
            </ul>
          )}

          {data && data.data.length === 0 && (
            <div className="mx-auto my-24 text-center">
              <HandThumbDownIcon className="inline-block w-12 text-slate-400" />
              <div className="mt-2 text-lg text-slate-500">
                Sorry, no results for you yet
              </div>
            </div>
          )}

          {isSearching && <Spinner className="mx-auto mt-[calc(40vh-300px)]" />}

          {error && !data && (
            <div className="mx-auto my-24 text-center">
              <ExclamationCircleIcon className="inline-block w-12 text-red-400" />
              <div className="mt-2 text-lg text-slate-500">{error.message}</div>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
