import {useState, useEffect} from 'react'

const useIsPrinting = () => {
  const [isPrinting, setIsPrinting] = useState(false)

  useEffect(() => {
    const mediaQueryList = window.matchMedia('print')

    const handleMediaChange = (e: MediaQueryListEvent) => {
      setIsPrinting(e.matches)
    }

    mediaQueryList.addEventListener('change', handleMediaChange)

    const handleBeforePrint = () => setIsPrinting(true)
    const handleAfterPrint = () => setIsPrinting(false)

    window.addEventListener('beforeprint', handleBeforePrint)
    window.addEventListener('afterprint', handleAfterPrint)

    return () => {
      mediaQueryList.removeEventListener('change', handleMediaChange)
      window.removeEventListener('beforeprint', handleBeforePrint)
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [])

  return {isPrinting, setIsPrinting}
}

export default useIsPrinting
