import {Routes, Route} from 'react-router-dom'

import ListDetailPage from './pages/ListDetailPage'
import ItemDetailPage from './pages/ItemDetailPage'
import NotFoundPage from './pages/NotFoundPage'
import MocksIndex from './pages/MocksIndex'
import ForYouPage from './pages/ForYouPage'
import SearchPage from './pages/SearchPage'
import AdminPage from './pages/AdminPage'
import LoginPage from './pages/LoginPage'
import SelectGradePage from './pages/SelectGrade'

import {ToastsProvider} from './components/ToastsProvider'
import {useMockedApi} from './config'
import Layout from './components/Layout'
import Auth from './components/Auth'
import ConfirmPage from './pages/ConfirmPage'

function App() {
  return (
    <ToastsProvider>
      <Routes>
        {useMockedApi && <Route path="/" element={<MocksIndex />} />}
        <Route element={<Auth />}>
          <Route element={<Layout />}>
            <Route path="/for-you" element={<ForYouPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/list/:id" element={<ListDetailPage />} />
            <Route path="/item/:id" element={<ItemDetailPage />} />
            <Route path="/login" element={<SearchPage />} />
            <Route path="/profile" element={<SelectGradePage />} />
            <Route path="/" element={<SearchPage />} />
          </Route>
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/confirm" element={<ConfirmPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ToastsProvider>
  )
}

export default App
