import {
  PaperAirplaneIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {Dialog, Transition} from '@headlessui/react'
import {NavLink, Outlet} from 'react-router-dom'
import * as React from 'react'

import {classNames} from '../utils'
import useUserLists from '../hooks/useUserLists'
import useLogout from '../hooks/useLogout'
import useUser from '../hooks/useUser'

import Spinner from './Spinner'
import useSiteConfiguration from '../hooks/useSiteConfiguration'

function Sidebar() {
  const user = useUser()

  const {data, error, isValidating} = useUserLists()

  const {config, loading} = useSiteConfiguration()

  const logout = useLogout()

  const isLoadingLists = !data && !error

  return (
    <div className="flex h-screen flex-col bg-blue-900 p-4 text-white">
      <img
        className="block bg-white py-1 px-px [border-radius:12px_0_12px_12px]"
        referrerPolicy="no-referrer"
        height={72}
        width={72}
        src="/logo.jpeg"
        alt=""
      />

      <div className="flex grow flex-col">
        <nav>
          <ul className="-mx-2 mt-12 mb-8">
            <li>
              <NavLink
                className={({isActive}) =>
                  classNames(
                    isActive ? 'font-semibold text-white' : 'text-slate-200',
                    '-my-1 block rounded-md px-2 py-1 text-base leading-8 outline-none hover:bg-white/20 focus-visible:ring-2',
                  )
                }
                to="/for-you">
                For you
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({isActive}) =>
                  classNames(
                    isActive ? 'font-semibold text-white' : 'text-slate-200',
                    '-my-1 block rounded-md px-2 py-1 text-base leading-8 outline-none hover:bg-white/20 focus-visible:ring-2',
                  )
                }
                to="/search">
                Browse all
              </NavLink>
            </li>
            {(user.data?.type === 'admin' ||
              user.data?.type === 'teacherAdmin') && (
              <li>
                <NavLink
                  className={({isActive}) =>
                    classNames(
                      isActive ? 'font-semibold text-white' : 'text-slate-200',
                      '-my-1 block rounded-md px-2 py-1 text-base leading-8 outline-none hover:bg-white/20 focus-visible:ring-2',
                    )
                  }
                  to="/admin">
                  Review
                </NavLink>
              </li>
            )}
          </ul>
        </nav>

        <nav className="mt-4 flex grow flex-col">
          <h2 className="flex items-center justify-between text-xs font-semibold uppercase text-slate-300/80">
            {user.data?.type === 'admin' || user.data?.type === 'teacherAdmin'
              ? 'Lists '
              : 'Your lists '}
            {isValidating && !isLoadingLists && (
              <Spinner reverse size="small" className="" />
            )}
          </h2>

          {data && data.length > 0 && (
            <div className="relative -mx-3 grow">
              <ul className="absolute inset-0 overflow-y-scroll py-2">
                {data
                  .sort((list1, list2) => {
                    if (
                      list1.author?.username === user.data?.username &&
                      list2.author?.username !== user.data?.username
                    ) {
                      return -1
                    }
                    if (
                      list1.author?.username !== user.data?.username &&
                      list2.author?.username === user.data?.username
                    ) {
                      return 1
                    }
                    return 0
                  })
                  .map(list => {
                    let owner = list.author?.email.substring(
                      0,
                      list.author?.email.indexOf('@'),
                    )
                    owner = `${owner?.substring(0, 4)}...`
                    return (
                      <li key={list.id}>
                        <NavLink
                          className={({isActive}) =>
                            classNames(
                              isActive
                                ? 'font-semibold text-white'
                                : 'text-slate-200',
                              '-my-1 mx-1 flex items-baseline justify-between gap-3 rounded-md px-2 py-2 outline-none hover:bg-white/20 focus-visible:ring-2',
                            )
                          }
                          to={'/list/' + list.id}>
                          <span className="grow overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                            {user.data?.type === 'admin' ||
                            user.data?.type === 'teacherAdmin'
                              ? list.author?.username === user.data?.username
                                ? '(Yours) '
                                : `(${owner}) ` ?? '(School) '
                              : ''}
                            {list.name}
                          </span>
                          <span className="whitespace-nowrap text-xs italic text-slate-300/70">
                            {list.items.length}
                          </span>
                          <PaperAirplaneIcon
                            className={classNames(
                              list.status === 'approved' && 'text-green-500',
                              list.status === 'draft' && 'text-transparent',
                              list.status === 'pending approval' &&
                                'text-orange-300',
                              '-mr-1 w-4 shrink-0 -rotate-45',
                            )}
                          />
                        </NavLink>
                      </li>
                    )
                  })}
              </ul>
            </div>
          )}

          {isLoadingLists && (
            <Spinner reverse size="small" className="my-6 mx-auto" />
          )}

          {data && data.length === 0 && (
            <p className="my-3 text-sm italic text-slate-300/60">
              No lists yet
            </p>
          )}

          {error && !data && (
            <p className="-mx-2 my-2 rounded-md px-2 py-1 text-sm text-red-300/90">
              {error.message}
            </p>
          )}
        </nav>
      </div>

      <nav>
        <ul className="-mx-2 mt-4">
          <li>
            <NavLink
              className={({isActive}) =>
                classNames(
                  isActive ? 'text-white' : 'text-slate-200',
                  '-my-1 block  rounded-md px-2 py-1 text-base leading-8 outline-none hover:bg-white/20 focus-visible:ring-2',
                )
              }
              to="/profile">
              Change Grade
            </NavLink>
          </li>
          {!loading && !!config.instructivePdfUrl && (
            <li>
              <a
                href={config.instructivePdfUrl}
                className="block rounded-md px-2 py-1 text-base leading-8 text-white outline-none hover:bg-white/20 focus-visible:ring-2"
                target="_blank"
                rel="noopener noreferrer">
                Instructive
              </a>
            </li>
          )}
          <li>
            <button
              className="-my-1 block w-full rounded-md px-2 py-1 text-left text-base leading-8 text-slate-200 outline-none hover:bg-white/20 focus-visible:ring-2"
              type="button"
              onClick={logout}>
              Log out
            </button>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default function Layout() {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <>
      <div className="hidden print:block">
        <Outlet />
      </div>
      <div className="print:hidden">
        {!isOpen && (
          <button
            className="fixed top-4 right-4 rounded-full p-2 text-blue-800 outline-none hover:text-blue-600 focus-visible:ring-2 sm:hidden"
            onClick={() => setIsOpen(true)}>
            <Bars3Icon aria-label="Open menu" className="block w-8 " />
          </button>
        )}
        <main className="py-6 pr-8 pl-8 sm:ml-72 sm:py-12">
          <Outlet />
        </main>

        <div className="fixed top-0 left-0 hidden h-screen w-72 sm:block">
          <Sidebar />
        </div>

        <Transition show={isOpen} as={React.Fragment}>
          <Dialog
            className="relative z-50 sm:hidden"
            onClose={() => setIsOpen(false)}
            unmount={false}
            open={isOpen}>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            </Transition.Child>

            <Dialog.Panel className="fixed ">
              <button
                className="fixed top-4 right-4 rounded-full p-2 text-blue-600 outline-none hover:text-blue-800 focus-visible:ring-2 sm:hidden"
                onClick={() => setIsOpen(false)}>
                <XMarkIcon aria-label="Close menu" className="block w-8" />
              </button>
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div className="fixed top-0 left-0 block h-screen w-72 sm:hidden">
                  <Sidebar />
                </div>
              </Transition.Child>
            </Dialog.Panel>
          </Dialog>
        </Transition>
      </div>
    </>
  )
}
