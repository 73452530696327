import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {SubmitHandler, useForm} from 'react-hook-form'
import {getAccessCode} from '../api/authApi'
import {ErrorMessage} from '@hookform/error-message'
import {useMockedApi} from '../config'

type LoginData = {
  email: string
}

export default function LoginPage() {
  const {
    handleSubmit,
    formState: {errors},
    register,
    getValues,
  } = useForm<LoginData>({shouldUnregister: true})
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const onSubmit: SubmitHandler<LoginData> = async ({email}) => {
    setLoading(true)
    const access = await getAccessCode(email)

    if (access) {
      setSuccess(true)
      setError(false)
      localStorage.setItem('userEmail', email)
      navigate('/confirm')
    } else {
      setSuccess(false)
      setError(true)
    }
    setLoading(false)
  }

  return (
    <div className="container mx-auto my-20 max-w-xl px-4">
      <h1 className="mb-4 text-3xl">Login Page</h1>
      <p className="text-sm leading-6 text-slate-600">
        Enter your email address, so that we can send you an access link:
      </p>
      <form className="mt-4 " onSubmit={handleSubmit(onSubmit)}>
        <label className="text-sm text-slate-500" htmlFor="name">
          Email
        </label>
        <input
          disabled={loading || success}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          className="block w-full border-b-2 text-lg outline-none focus:border-blue-700"
          type="email"
          {...register('email', {
            setValueAs: value => value.trim(),
            required: 'Required',
          })}
        />
        <div className="mt-1 h-5">
          <ErrorMessage
            className="text-xs text-red-600"
            errors={errors}
            name="email"
            as="div"
          />
        </div>
        {(error || success) && (
          <div className="mt-1 h-5" style={{marginBottom: '48px'}}>
            {error && (
              <div className="text-xs text-red-600">
                <p>{getValues().email} is not a valid email</p>
              </div>
            )}
            {success && (
              <div className="text-xs text-green-600">
                <p>An access link was sent to {getValues().email}</p>
                <p>
                  {useMockedApi && (
                    <a href="http://localhost:3000/for-you?token=admin-invite-token">
                      Access as ADMIN
                    </a>
                  )}
                </p>
                <p>
                  {useMockedApi && (
                    <a href="http://localhost:3000/for-you?token=user-invite-token">
                      Access as USER
                    </a>
                  )}
                </p>
              </div>
            )}
          </div>
        )}

        {!success && (
          <div className="mt-3 flex gap-4">
            <button
              disabled={loading}
              className="flex items-center gap-2 rounded-md bg-blue-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-blue-400 ring-offset-2 hover:bg-blue-600 focus-visible:ring-2 disabled:bg-blue-300"
              type="submit">
              Send
            </button>
          </div>
        )}
      </form>
      {useMockedApi && (
        <p className="mt-2 text-sm leading-6 text-slate-600">
          <Link
            className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
            to="/">
            Back to index
          </Link>
        </p>
      )}
    </div>
  )
}
