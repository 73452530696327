import {
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
  BuildingLibraryIcon,
  AcademicCapIcon,
  ChevronDownIcon,
  UserCircleIcon,
  UserGroupIcon,
  ChevronUpIcon,
  NoSymbolIcon,
  ChatBubbleLeftIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline'
import {Menu, Transition} from '@headlessui/react'
import {prop, uniqBy} from 'ramda'
import * as React from 'react'
import {Link} from 'react-router-dom'

import {Item, List, SchoolLists, SentList} from '../types'
import {classNames} from '../utils'
import useAdminLists from '../hooks/useAdminLists'
import Spinner from '../components/Spinner'
import useUser from '../hooks/useUser'
import {updateListStatus} from '../api/adminApi'
import {useToasts} from '../components/ToastsProvider'
import {useSWRConfig} from 'swr'
import useIsPrinting from '../hooks/useIsPrinting'
import AdminPrintPage from './AdminPrintPage'
import AdminItem from '../components/AdminItem'
import {removeItemFromList} from '../api/listApi'

function getSchoolProgress(school: SchoolLists): {
  approvedLevels: number
  pendingLevels: number
  totalLevels: number
} {
  let approvedLevels = 0
  let pendingLevels = 0
  let totalLevels = 0
  Object.values(school.levels).forEach(lists => {
    totalLevels++
    if (lists.some(list => list.status === 'approved')) {
      approvedLevels++
    }
    // supposedly we won't have draft lists here, but just in case...
    else if (lists.some(list => list.status === 'pending approval')) {
      pendingLevels++
    }
  })
  return {approvedLevels, pendingLevels, totalLevels}
}

function NotAllowed() {
  return (
    <div className="mx-auto my-24 text-center">
      <NoSymbolIcon className="inline-block w-12 text-slate-400" />
      <div className="mt-2 text-lg text-slate-500">
        You can&apos;t access this page
      </div>
    </div>
  )
}

function LevelSection({
  lists,
  isPrinting,
}: {
  lists: SentList[]
  isPrinting: boolean
}) {
  const [selectLists, setSelectLists] = React.useState(lists)
  const {addToast} = useToasts()
  const global = useSWRConfig()

  const handleRemoveItemFromList = async (item: Item, list: List) => {
    try {
      await removeItemFromList(item.id, list.id)
      const newList = selectLists.map((currentList: any) =>
        list.id === currentList.id
          ? {
              ...currentList,
              items: currentList.items.filter(
                (itemList: any) => itemList.id !== item.id,
              ),
            }
          : currentList,
      )
      setSelectLists(newList)
      addToast(`${item.title} removed from list`, {
        variant: 'success',
      })
    } catch (error) {
      addToast('Error removing item from list', {
        variant: 'danger',
      })
    }
  }

  return (
    <section className="mt-2 ml-4 rounded-md bg-white py-1 px-4">
      <ul className="divide-y">
        {selectLists.map(list => {
          let isExpanded = isPrinting
          // For now we'll force expansion
          isExpanded = true
          return (
            <li key={list.id} className="border-none">
              <header
                className={classNames(
                  'relative -mx-4 grid grid-cols-[200px_1fr_1fr_1fr_1fr_min-content] items-baseline gap-4 px-4 pt-2 pb-3',
                  !isExpanded && 'hover:bg-slate-50',
                )}>
                <Link
                  to={`/list/${list.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames(
                    "overflow-hidden overflow-ellipsis whitespace-nowrap text-left before:absolute before:inset-0 before:content-['']",
                    isExpanded
                      ? 'text-lg font-semibold'
                      : 'text-sm text-slate-600',
                  )}>
                  {list.name}
                </Link>

                <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-slate-600">
                  {!isExpanded && (
                    <>
                      <UserCircleIcon className="relative -top-0.5 mr-1 inline-block w-5 align-middle text-slate-400" />
                      {list.author.username}
                    </>
                  )}
                </div>

                <div className="text-xs text-slate-600">
                  {!isExpanded && (
                    <>
                      <UserGroupIcon className="relative -top-0.5 mr-1.5 inline-block w-5 align-middle text-slate-400" />
                      {list.studentsAmount}{' '}
                      {list.studentsAmount === 1 ? 'student' : 'students'}
                    </>
                  )}
                </div>

                <div className="flex items-baseline gap-2 text-xs">
                  {!isExpanded && (
                    <>
                      <div className="-mt-1 flex flex-row-reverse self-center">
                        {list.items.slice(-3).map(book =>
                          book.thumbnail ? (
                            <div className="relative">
                              <img
                                className="relative -ml-2 block max-w-[80px] rounded-sm bg-slate-200 shadow-md first:top-0.5 last:-top-0.5 last:ml-0"
                                src={
                                  book.thumbnail || '/Image_not_available.png'
                                }
                                key={book.id}
                                alt=""
                              />
                              <div className="absolute top-1 right-1 rounded-md border-black bg-black p-1 text-white">
                                {list.amounts?.[book.id] || list.studentsAmount}
                              </div>
                            </div>
                          ) : null,
                        )}
                      </div>
                      <span className="whitespace-nowrap">
                        {list.items.length}{' '}
                        {list.items.length === 1 ? 'book' : 'books'}
                      </span>
                    </>
                  )}
                </div>

                <div>
                  {!isExpanded && (
                    <div
                      className={classNames(
                        list.status === 'approved' &&
                          'border-green-400 text-green-600',
                        list.status === 'pending approval' &&
                          'border-orange-300 text-orange-500',
                        'inline-block whitespace-nowrap rounded-md border bg-white px-2 py-0.5 text-xs',
                      )}>
                      {list.status === 'pending approval'
                        ? 'Pending Approval'
                        : 'Approved'}
                    </div>
                  )}
                </div>

                <Menu as="div" className="relative">
                  <Menu.Button
                    className="shrink-0 rounded-full p-2 text-slate-400 outline-none hover:bg-blue-100 hover:text-blue-700 focus-visible:ring-2 "
                    aria-label="List menu">
                    <EllipsisHorizontalIcon className="h-6 w-6 text-slate-400" />
                  </Menu.Button>
                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    as={React.Fragment}>
                    <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({active}) => (
                          <button
                            className={classNames(
                              active && 'bg-gray-100',
                              'flex w-full items-center gap-2 px-4 py-2 text-left text-sm disabled:cursor-not-allowed disabled:opacity-40',
                            )}
                            onClick={() => {
                              global.mutate('adminLists', () =>
                                updateListStatus(
                                  list.id,
                                  list.status === 'approved'
                                    ? 'pending approval'
                                    : 'approved',
                                ).then(res => {
                                  addToast('List updated', {
                                    variant: 'success',
                                  })
                                  return res
                                }),
                              )
                            }}>
                            {list.status === 'approved'
                              ? 'Set as Pending'
                              : 'Approve list'}
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({active}) => (
                          <button
                            className={classNames(
                              active && 'bg-gray-100',
                              'flex w-full items-center gap-2 px-4 py-2 text-left text-sm disabled:cursor-not-allowed disabled:opacity-40',
                            )}
                            disabled>
                            Archive
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </header>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition-all duration-75 ease-out"
                leaveFrom="transform opacity-100 max-h-screen"
                leaveTo="transform opacity-0 max-h-0"
                show={isExpanded}>
                <div className="flex items-baseline gap-12">
                  <div className="text-sm text-slate-600">
                    <UserCircleIcon className="relative -top-0.5 mr-1.5 inline-block w-5 align-middle text-slate-400" />
                    By {list.author.username}
                    <div className="ml-6 mr-4 -mt-1 font-mono text-[11px] text-slate-400">
                      {list.author.email}
                    </div>
                  </div>

                  <div className="text-sm text-slate-600">
                    <UserGroupIcon className="relative -top-0.5 mr-1.5 inline-block w-5 align-middle text-slate-400" />
                    {list.studentsAmount}{' '}
                    {list.studentsAmount === 1 ? 'student' : 'students'}
                  </div>

                  <div
                    className={classNames(
                      list.status === 'approved' &&
                        'border-green-400 text-green-600',
                      list.status === 'pending approval' &&
                        'border-orange-300 text-orange-500',
                      'ml-6 inline-block whitespace-nowrap rounded-md border bg-white px-4 py-1 text-sm',
                    )}>
                    {list.status === 'pending approval'
                      ? 'Pending Approval'
                      : 'Approved'}
                  </div>
                </div>

                {list.message && (
                  <div className="-mx-2 mt-2 flex items-baseline rounded-md p-2">
                    <ChatBubbleLeftIcon className="mr-1.5 w-5 shrink-0 self-start text-slate-400" />
                    <span className="text-sm text-slate-600">
                      <strong className="font-semibold text-slate-500">
                        Author message:{' '}
                      </strong>
                      {list.message}
                    </span>
                  </div>
                )}

                <div className="page-break-inside-avoid">
                  <p className="mt-4 text-sm text-slate-500">
                    {list.items.length}{' '}
                    {list.items.length === 1 ? 'book' : 'books'}
                  </p>

                  <ul className="mt-2 grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-x-6 gap-y-4 pb-4">
                    {list.items.map(item =>
                      list.status === 'pending approval' ? (
                        <AdminItem
                          item={item}
                          list={list}
                          handleRemoveItemFromList={handleRemoveItemFromList}
                        />
                      ) : (
                        <li className="relative flex flex-col" key={item.id}>
                          {!!item.thumbnail && (
                            <img
                              className="block max-w-[80px] bg-slate-200 shadow-md"
                              width={210 / 4}
                              height={297 / 4}
                              src={item.thumbnail || '/Image_not_available.png'}
                              alt=""
                            />
                          )}
                          <Link
                            className="z-10 mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2 underline-offset-2 hover:underline"
                            to={'/item/' + item.id}>
                            {item.title}
                          </Link>
                          <div className="flex flex-col">
                            <div className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2">
                              Number of students:{' '}
                              <strong>
                                {list.amounts?.[item.id] || list.studentsAmount}
                              </strong>
                            </div>
                            <div className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2">
                              KEL Code: <strong>{item.kelCode}</strong>
                            </div>
                            <div className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2">
                              ISBN: <strong>{item.isbn}</strong>
                            </div>
                          </div>
                        </li>
                      ),
                    )}
                  </ul>
                </div>
              </Transition>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

function SchoolSection({
  school,
  isPrinting,
}: {
  school: SchoolLists
  isPrinting: boolean
}) {
  const [selectedLevel, setSelectedLevels] = React.useState<string[]>([])
  const {approvedLevels, pendingLevels, totalLevels} = getSchoolProgress(school)

  return (
    <section className="rounded-md border-none bg-transparent py-4 px-3 shadow">
      <header className="flex">
        <BuildingLibraryIcon className="relative -top-0.5 mr-2 inline-block w-6 text-slate-600" />
        <span className="grow text-xl text-slate-600">{school.name}</span>
        <div className="relative ml-2 mr-3 h-3 w-1/6 self-center overflow-hidden rounded-md bg-slate-300 shadow-inner">
          <div
            className="absolute h-3 bg-yellow-300 shadow-inner transition-[width] duration-500"
            style={{
              width: `${
                ((approvedLevels + pendingLevels) * 100) / totalLevels
              }%`,
            }}
          />
          <div
            className="absolute h-3 bg-green-400 shadow-inner transition-[width] duration-500"
            style={{width: `${(approvedLevels * 100) / totalLevels}%`}}
          />
        </div>
      </header>

      <ul className="mt-4 divide-y">
        {Object.entries(school.levels).map(([level, lists]) => {
          const uniqueBooksCount = uniqBy(
            prop('id'),
            lists.map(list => list.items).flat(),
          ).length
          const isExpanded = isPrinting || selectedLevel.includes(level)
          const hasLists = lists.length > 0
          return (
            <li
              className={classNames(
                // 'page-break-after-always',
                isExpanded ? 'pb-4' : 'pb-0',
              )}
              key={level}>
              <header
                className={classNames(
                  'group flex items-center gap-3 py-2',
                  isExpanded ? 'pt-2 pb-0' : 'py-2',
                  hasLists && 'cursor-pointer',
                )}
                onClick={() =>
                  hasLists
                    ? setSelectedLevels(currentLevels =>
                        currentLevels.includes(level)
                          ? currentLevels.filter(
                              currentLevel => currentLevel !== level,
                            )
                          : [...currentLevels, level],
                      )
                    : null
                }>
                <AcademicCapIcon
                  className={classNames(
                    'relative -top-0.5 ml-1 -mr-1 inline-block w-5 align-middle',
                    hasLists
                      ? 'text-slate-400 group-hover:text-blue-700'
                      : 'text-slate-300',
                  )}
                />

                <span
                  className={classNames(
                    'grow text-sm',
                    hasLists
                      ? 'font-semibold text-slate-600 group-hover:text-blue-700'
                      : 'text-slate-400',
                  )}>
                  {level.split('|').join(' - ')}
                </span>

                {hasLists ? (
                  <span className="text-xs text-slate-500">
                    {uniqueBooksCount}{' '}
                    {uniqueBooksCount === 1 ? 'book' : 'books'} in{' '}
                    {lists.length} {lists.length === 1 ? 'list' : 'lists'}
                  </span>
                ) : (
                  <span className="text-xs italic text-slate-400">
                    No lists
                  </span>
                )}

                <button
                  className="rounded-full p-2 text-slate-600 outline-none focus-visible:ring-2 disabled:opacity-0 group-hover:text-blue-600 disabled:group-hover:text-slate-300"
                  disabled={!hasLists}
                  onClick={ev => {
                    ev.stopPropagation()
                    setSelectedLevels(currentLevels =>
                      currentLevels.includes(level)
                        ? currentLevels.filter(
                            currentLevel => currentLevel !== level,
                          )
                        : [...currentLevels, level],
                    )
                  }}
                  type="button">
                  {isExpanded ? (
                    <ChevronUpIcon className="h-4 w-4" />
                  ) : (
                    <ChevronDownIcon className="h-4 w-4" />
                  )}
                </button>
              </header>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition-all duration-75 ease-out"
                leaveFrom="transform opacity-100 max-h-screen"
                leaveTo="transform opacity-0 max-h-0"
                show={isExpanded}>
                <LevelSection lists={lists} isPrinting={isPrinting} />
              </Transition>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

function AdminLists() {
  const {data, error} = useAdminLists({keepPreviousData: true})
  const isLoading = !data && !error

  const {isPrinting, setIsPrinting} = useIsPrinting()

  function print() {
    setIsPrinting(true)
    setTimeout(() => {
      window.print()
    })
  }

  return (
    <div>
      <AdminPrintPage schools={data || []} />

      {data && (
        <>
          <div className="flex flex-row gap-2 align-middle print:hidden">
            <h1 className="-mt-2 text-xl">
              Review your {data.length === 1 ? 'school' : 'schools'} lists
            </h1>
            <PrinterIcon
              width={25}
              height={25}
              className="mt-[-5px] cursor-pointer"
              onClick={print}
            />
          </div>

          {data.length ? (
            <>
              <p className="mt-3 text-sm text-slate-500 print:hidden">
                {data.length} {data.length === 1 ? 'school' : 'schools'}
              </p>

              <ul className="mt-4 max-w-6xl space-y-4 print:hidden">
                {data.map(school => {
                  return (
                    <li key={school.id}>
                      <SchoolSection school={school} isPrinting={isPrinting} />
                    </li>
                  )
                })}
              </ul>
            </>
          ) : (
            <div className="mx-auto my-24 text-center">
              <BuildingLibraryIcon className="inline-block w-12 text-slate-400" />
              <div className="mt-2 text-2xl text-slate-600">
                No schools to review yet
              </div>
              <div className="mt-2 text-sm text-slate-500">
                Lists sent for review will appear here organized by school and
                academic level
              </div>
            </div>
          )}
        </>
      )}

      {isLoading && <Spinner className="mx-auto mt-[calc(50vh-300px)]" />}

      {error && !data && (
        <div className="mx-auto my-24 text-center">
          <ExclamationCircleIcon className="inline-block w-12 text-red-400" />
          <div className="mt-2 text-lg text-slate-500">{error.message}</div>
        </div>
      )}
    </div>
  )
}

export default function AdminPage() {
  const {data} = useUser()
  return data && (data.type === 'admin' || data.type === 'teacherAdmin') ? (
    <AdminLists />
  ) : (
    <NotAllowed />
  )
}
