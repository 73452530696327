import type {SWRConfiguration} from 'swr'
import useSWR from 'swr'

import {getHistory} from '../api/listApi'
import {History} from '../types'

export default function useHistory(
  id: History['id'],
  options?: SWRConfiguration,
) {
  return useSWR<History[], Error>(
    `/list/${id}/history`,
    () => getHistory(id),
    options,
  )
}
