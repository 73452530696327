export default function NotFoundPage() {
  return (
    <div className="flex h-screen items-center">
      <main className="container mx-auto mb-32 p-4 text-center">
        <h1 className="text-4xl text-blue-700">404 - Not Found</h1>
        <p className="mt-4 text-slate-600">This route does not exist</p>
      </main>
    </div>
  )
}
