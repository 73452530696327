import {useSearchParams} from 'react-router-dom'
import {XMarkIcon} from '@heroicons/react/20/solid'

const omittedParams = ['q', 'sort']

interface Props {
  onClick(group: string, value: string, isChecked: boolean): void
}

export default function FilterChips(props: Props) {
  const [searchParams] = useSearchParams()

  const chips = [...searchParams.entries()].filter(
    ([p]) => !omittedParams.includes(p),
  )

  return (
    <ul className="flex min-h-[20px] flex-wrap gap-1">
      {chips.map(([key, value]) => {
        return (
          <li key={`${key}-${value}`}>
            <button
              className="group block rounded-md bg-blue-100 pl-2 text-xs leading-5 text-slate-500 outline-none ring-offset-1 hover:bg-blue-200 hover:text-slate-700 focus-visible:ring-2"
              onClick={() => props.onClick(key, value, false)}
              type="button">
              {value === 'true'
                ? key === 'isRecommended'
                  ? 'Recommended'
                  : key
                : value.split('|').join(' - ')}
              <XMarkIcon className="ml-1 mr-1 -mt-0.5 inline-block w-3 text-blue-500 group-hover:text-blue-800" />
            </button>
          </li>
        )
      })}
    </ul>
  )
}
