import {useState} from 'react'
import {Link} from 'react-router-dom'
import {SubmitHandler, useForm} from 'react-hook-form'
import {setSelectedLevel} from '../api/authApi'

import {useMockedApi} from '../config'
import useSchool from '../hooks/useSchool'
import useUser from '../hooks/useUser'

import {SearchLevel} from '../types'
import GradeSelector from '../components/GradeSelector'
import useLogout from '../hooks/useLogout'

type FormData = {
  level?: SearchLevel
}

export default function SelectGrade() {
  const form = useForm<FormData>({shouldUnregister: true})

  const logout = useLogout()

  const {handleSubmit, getValues} = form

  const user = useUser()
  const schoolFromAPI = useSchool()

  const [screenLoading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const loading = screenLoading || user.isLoading || schoolFromAPI.isLoading

  const onSubmit: SubmitHandler<FormData> = async ({level}) => {
    setLoading(true)
    const updated = level ? await setSelectedLevel(level) : false
    if (updated) {
      setSuccess(true)
      setError(false)
      window.localStorage.setItem(
        'user',
        JSON.stringify({...user.data, selectedGrade: level}),
      )
      user.mutate()
    } else {
      setSuccess(false)
      setError(true)
    }
    setLoading(false)
  }

  return (
    <div className="container mx-auto my-20 max-w-xl px-4">
      <h1 className="mb-4 text-3xl">Select grade</h1>
      {!user.data?.selectedGrade && (
        <p className="text-sm leading-6 text-slate-600">
          Before using the site, we need you to select your grade (you can
          update it later)
        </p>
      )}
      {!!user.data?.selectedGrade && (
        <p className="text-sm leading-6 text-slate-600">
          Update your selected grade
        </p>
      )}
      <form className="mt-4 " onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-0 grid grid-cols-2 gap-8">
          <GradeSelector form={form} screenLoading={loading} />
        </div>

        {(error || success) && (
          <div className="mt-1 h-5" style={{marginBottom: '48px'}}>
            {error && (
              <div className="text-xs text-red-600">
                <p>{getValues().level} is not a valid level</p>
              </div>
            )}
            {success && (
              <div className="text-xs text-green-600">
                <p>Level updated to {getValues().level}</p>
              </div>
            )}
          </div>
        )}

        {!success && (
          <div className="mt-3 flex gap-4">
            <button
              disabled={loading}
              className="flex items-center gap-2 rounded-md bg-blue-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-blue-400 ring-offset-2 hover:bg-blue-600 focus-visible:ring-2 disabled:bg-blue-300"
              type="submit">
              Update it
            </button>
            <button
              disabled={loading}
              className="flex items-center gap-2 rounded-md bg-red-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-red-400 ring-offset-2 hover:bg-red-600 focus-visible:ring-2 disabled:bg-red-300"
              type="button"
              onClick={logout}>
              Log out
            </button>
          </div>
        )}
      </form>
      {useMockedApi && (
        <p className="mt-2 text-sm leading-6 text-slate-600">
          <Link
            className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
            to="/">
            Back to index
          </Link>
        </p>
      )}
    </div>
  )
}
