import {Link} from 'react-router-dom'

import {List} from '../types'

export default function MocksIndex() {
  const localItems = JSON.parse(window.localStorage.getItem('items') || '[]')
  const localLists = JSON.parse(window.localStorage.getItem('lists') || '[]')
  return (
    <div className="container mx-auto my-20 max-w-xl px-4">
      <h1 className="mb-4 text-3xl">Mocked states</h1>
      <p className="text-sm leading-6 text-slate-600">
        Quick access to every possible state using a{' '}
        <a
          className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
          target="_blank"
          href="https://mswjs.io/"
          rel="noreferrer">
          mocked Service Worker API
        </a>{' '}
        currently consuming <strong>{localItems.length} fake items</strong> and{' '}
        <strong>{localLists.length} fake lists</strong> from your browser&apos;s{' '}
        <code>localStorage</code>.
      </p>

      <p className="mt-4 text-sm leading-6 text-slate-600">
        Use set the <code>REACT_APP_USE_MOCKED_API</code> environment variable
        to <code>false</code> or remove it to use a live API and disable this
        page.
      </p>

      <nav>
        <h2 className="mt-12 text-xs font-semibold uppercase text-slate-500">
          Main
        </h2>
        <ul className="mt-4 list-disc space-y-3 pl-5 marker:text-slate-400">
          <li>
            <Link
              className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
              to="/for-you?token=invite-token">
              &laquo;For you&raquo; page
            </Link>
          </li>
          <li>
            <Link
              className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
              to="/search?token=invite-token">
              Search results
            </Link>
          </li>
          <li>
            <Link
              className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
              to={'/item/' + localItems[0].id + '?token=invite-token'}>
              Item detail
            </Link>
          </li>
        </ul>
      </nav>

      <nav>
        <h2 className="mt-12 text-xs font-semibold uppercase text-slate-500">
          User Lists
        </h2>
        <ul className="mt-4 list-disc space-y-3 pl-5 marker:text-slate-400">
          <li>
            <Link
              className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
              to={
                '/list/' +
                localLists.filter((l: List) => l.status === 'draft')[0].id +
                '?token=invite-token'
              }>
              Draft
            </Link>
          </li>
          <li>
            <Link
              className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
              to={
                '/list/' +
                localLists.filter(
                  (l: List) => l.status === 'pending approval',
                )[0].id +
                '?token=invite-token'
              }>
              Pending approval
            </Link>
          </li>
          <li>
            <Link
              className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
              to={
                '/list/' +
                localLists.filter((l: List) => l.status === 'approved')[0].id +
                '?token=invite-token'
              }>
              Approved
            </Link>
          </li>
        </ul>
      </nav>

      <nav>
        <h2 className="mt-12 text-xs font-semibold uppercase text-slate-500">
          ADMINS
        </h2>
        <ul className="mt-4 list-disc space-y-3 pl-5 marker:text-slate-400">
          <li>
            <Link
              className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
              to="/admin?token=admin-invite-token">
              Admin view
            </Link>
          </li>
        </ul>
      </nav>

      <nav>
        <h2 className="mt-12 text-xs font-semibold uppercase text-slate-500">
          Misc
        </h2>
        <ul className="mt-4 list-disc space-y-3 pl-5 marker:text-slate-400">
          <li>
            <Link
              className="text-blue-600 underline decoration-blue-200 underline-offset-2 hover:text-blue-800"
              to="/not-found">
              404 page
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}
