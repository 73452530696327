import {GENERIC_ERROR_MESSAGE} from './common'
import {apiBaseUrl} from '../config'
import {Item, List, History} from '../types'
import authorizationHeader from './authorizationHeader'

export async function getUserLists(): Promise<List[]> {
  return fetch(apiBaseUrl + '/lists', {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok)
      throw new Error('Sorry, there was a problem retrieving your lists.')
    return res.json()
  })
}

export async function createList(name: string, items?: Item[]): Promise<List> {
  return fetch(apiBaseUrl + '/lists', {
    headers: authorizationHeader({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify({name, items}),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function deleteList(id: List['id']): Promise<List[]> {
  return fetch(apiBaseUrl + '/lists/' + id, {
    headers: authorizationHeader(),
    method: 'DELETE',
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function getList(id: List['id']): Promise<List> {
  return fetch(apiBaseUrl + '/lists/' + id, {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function getHistory(id: string): Promise<History[]> {
  return fetch(apiBaseUrl + '/lists/' + id + '/history', {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function updateList(
  id: List['id'],
  newData: Partial<
    Pick<List, 'name' | 'schoolHubspotId' | 'level' | 'studentsAmount'>
  >,
): Promise<List> {
  const body = {} as Partial<List>
  if (newData.name) body.name = newData.name
  if (newData.schoolHubspotId) body.schoolHubspotId = newData.schoolHubspotId
  if (newData.studentsAmount) body.studentsAmount = newData.studentsAmount
  if (newData.level) body.level = newData.level
  return fetch(apiBaseUrl + '/lists/' + id, {
    headers: authorizationHeader({
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
    body: JSON.stringify(body),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function updateItemListAmount(
  id: List['id'],
  itemId: string,
  amount?: number,
): Promise<List> {
  return fetch(apiBaseUrl + '/lists/' + id + '/item/' + itemId, {
    headers: authorizationHeader({
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
    body: JSON.stringify({amount}),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function addItemToList(
  itemId: Item['id'],
  listId: List['id'],
): Promise<List> {
  return fetch(apiBaseUrl + '/lists/' + listId + '/items/' + itemId, {
    headers: authorizationHeader(),
    method: 'PUT',
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function removeItemFromList(
  itemId: Item['id'],
  listId: List['id'],
): Promise<List> {
  return fetch(apiBaseUrl + '/lists/' + listId + '/items/' + itemId, {
    headers: authorizationHeader(),
    method: 'DELETE',
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function sendListForReview(
  listId: List['id'],
  message?: string,
): Promise<List> {
  return fetch(apiBaseUrl + '/lists/review/' + listId, {
    headers: authorizationHeader({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify({message, listId}),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}
