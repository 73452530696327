import {rest} from 'msw'

import {
  getLocalAdminLists,
  adminListsFactory,
  getLocalItems,
} from './mockHelpers'
import {SchoolLists, SentList} from '../types'
import {apiBaseUrl} from '../config'
import {AcademicLevel} from 'teachers-types'

if (!getLocalAdminLists().length) {
  window.localStorage.setItem(
    'adminLists',
    JSON.stringify(adminListsFactory(getLocalItems())),
  )
}

const getAdminListsHandler = rest.get<null, {}, SchoolLists[]>(
  apiBaseUrl + '/admin/lists',
  (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(getLocalAdminLists()), ctx.delay(600))
  },
)

const updateAdminListStatusHandler = rest.patch<
  {status: SentList['status']},
  {id: string},
  SchoolLists[]
>(apiBaseUrl + '/admin/lists/:id', async (req, res, ctx) => {
  const body = await req.json()
  const updatedResponse = getLocalAdminLists().map(school => ({
    ...school,
    levels: Object.entries(school.levels).reduce((acc, [level, lists]) => {
      acc[level as AcademicLevel] = lists.map(list =>
        list.id === req.params.id ? {...list, ...body} : list,
      )
      return acc
    }, {} as SchoolLists['levels']),
  }))
  window.localStorage.setItem('adminLists', JSON.stringify(updatedResponse))
  return res(ctx.status(200), ctx.json(updatedResponse), ctx.delay(600))
})

export const handlers = [getAdminListsHandler, updateAdminListStatusHandler]
