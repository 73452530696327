export const series = [
  'Bergnaum, Pfeffer and Beer',
  'Bernhard Group',
  'Bernier Group',
  'Boehm Group',
  'Braun and Sons',
  'Cronin and Sons',
  'Emard Inc',
  "Fay, McDermott and O'Keefe",
  'Funk Group',
  'Grant - Kuhic',
  'Green - Marvin',
  'Hane - Buckridge',
  'Hane LLC',
  'Hauck, Bradtke and Weissnat',
  'Hermiston - Schultz',
  'Hermiston LLC',
  'Hoppe, Schowalter and Durgan',
  'Jaskolski, Prosacco and White',
  'Johnson LLC',
  'Kautzer Inc',
  'Kunde, Rowe and Douglas',
  'Langosh, Beer and Satterfield',
  'Muller LLC',
  'Prohaska, Murray and Kertzmann',
  'Ruecker - Mitchell',
  'Schaden Inc',
  'Schamberger, Wilkinson and Lind',
  'Schinner, Reynolds and Spinka',
  'Thiel, Volkman and Hamill',
  'Watsica, Altenwerth and Dicki',
  'Wuckert - Lehner',
]
