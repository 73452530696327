import {Link} from 'react-router-dom'
import {Item, List} from '../types'
import {TrashIcon} from '@heroicons/react/24/outline'
import BaseDialog from './BaseDialog'
import React from 'react'

type Props = {
  item: Item
  list: List
  handleRemoveItemFromList: (item: Item, list: List) => void
}

const AdminItem = ({item, list, handleRemoveItemFromList}: Props) => {
  const [selectedItem, setSelectedItem] = React.useState<Item | null>(null)
  const [selectedList, setSelectedList] = React.useState<List | null>(null)
  const [action, setAction] = React.useState<
    'remove-item' | 'edit-item' | 'delete' | 'send' | 'edit' | null
  >(null)

  return (
    <>
      <li className="relative flex flex-col border-none" key={item.id}>
        <div className="relative mb-2">
          {!!item.thumbnail && (
            <div className="relative">
              <img
                className="block max-w-[80px] bg-slate-200 shadow-md"
                width={210 / 4}
                height={297 / 4}
                src={item.thumbnail || '/Image_not_available.png'}
                alt=""
              />
              <button
                className="absolute right-0 top-0 m-1 rounded-full bg-transparent p-1 shadow hover:bg-gray-100"
                onClick={() => {
                  setSelectedItem(item)
                  setSelectedList(list)
                  setAction('remove-item')
                }}>
                <TrashIcon className="w-4" color="red" />
              </button>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <Link
            className="z-10 mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2 underline-offset-2 hover:underline"
            to={'/item/' + item.id}>
            {item.title}
          </Link>
          <div className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2">
            Number of students:{' '}
            <strong>{list.amounts?.[item.id] || list.studentsAmount}</strong>
          </div>
          <div className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2">
            KEL Code: <strong>{item.kelCode}</strong>
          </div>
          <div className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2">
            ISBN: <strong>{item.isbn}</strong>
          </div>
        </div>
      </li>

      <BaseDialog
        noCloseButton
        afterLeave={() => {
          setSelectedItem(null)
          setSelectedList(null)
        }}
        onClose={() => {
          setSelectedItem(null)
          setSelectedList(null)
          setAction(null)
        }}
        isOpen={action === 'remove-item'}
        title="Remove Item"
        size="sm">
        <div className="mt-4">
          <p className="text-sm text-slate-500">
            Are you sure you that you want to remove{' '}
            <strong className="text-slate-600">{selectedItem?.title}</strong>{' '}
            from this list?
          </p>

          <div className="mt-6 flex gap-4">
            <button
              className="rounded-md bg-transparent bg-blue-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-blue-400 ring-offset-2 hover:bg-blue-600 focus-visible:ring-2 disabled:bg-blue-300"
              onClick={async () => {
                if (selectedItem && selectedList) {
                  await handleRemoveItemFromList(item, list)
                  setSelectedItem(null)
                  setSelectedList(null)
                  setAction(null)
                }
              }}>
              Remove
            </button>
            <button
              className="rounded-md px-4 py-3 text-sm font-semibold text-blue-500 underline outline-none ring-blue-400 ring-offset-2 hover:text-blue-600 focus-visible:ring-2 disabled:text-blue-300"
              onClick={() => {
                setSelectedItem(null)
                setSelectedList(null)
                setAction(null)
              }}>
              Cancel
            </button>
          </div>
        </div>
      </BaseDialog>
    </>
  )
}

export default AdminItem
