import type {SWRConfiguration} from 'swr'
import useSWR from 'swr'

import {SearchParams, SearchResponse} from '../types'
import {getSearchResults} from '../api/searchApi'
import useSchool from './useSchool'

export default function usePagedSearchResults(
  params: SearchParams,
  options?: SWRConfiguration,
) {
  const school = useSchool()

  const search = useSWR<SearchResponse, Error>(
    school.data ? ['search-results', params] : null,
    () => getSearchResults(params, school.data),
    options,
  )

  return search
}
