import { useSearchParams } from 'react-router-dom'
import { Switch } from '@headlessui/react'
import * as React from 'react'

import { classNames } from '../utils'

interface Props {
  className?: React.HTMLAttributes<HTMLDivElement>['className']
  onToggle(group: string, value: string, isChecked: boolean): void
  urlParam: string
  label: string
}

export default function BooleanFilterControl(props: Props) {
  const [searchParams] = useSearchParams()
  const currentParams = searchParams.getAll(props.urlParam)
  const isChecked = currentParams[0] === 'true'
  return (
    <Switch.Group
      className={classNames(
        props.className,
        'group relative flex items-center justify-between gap-2 py-1 hover:text-blue-700'
      )}
      as="div"
    >
      <Switch.Label className="ml-2 grow cursor-pointer text-sm font-semibold">
        {props.label}
      </Switch.Label>
      <Switch
        checked={isChecked}
        onChange={(newValue) =>
          props.onToggle(props.urlParam, 'true', newValue)
        }
        className={`${
          isChecked ? 'bg-blue-600' : 'bg-slate-300 group-hover:bg-blue-200'
        } relative mr-1 inline-flex h-5 w-9 items-center rounded-full outline-none ring-offset-2 focus-visible:ring-2`}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={`${
            isChecked ? 'translate-x-5' : 'translate-x-1 shadow'
          } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
        />
      </Switch>
    </Switch.Group>
  )
}
