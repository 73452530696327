import {FieldValues, UseFormReturn} from 'react-hook-form'

import {ErrorMessage} from '@hookform/error-message'
import useSchool from '../hooks/useSchool'
import useUser from '../hooks/useUser'
import {AcademicLevel, Grade, levels} from 'teachers-types'
import {SearchLevel} from '../types'

export type GradeSelectorFormData = {
  level?: SearchLevel
}

const keys = Object.keys(levels) as (keyof typeof levels)[]

const searchLevels: SearchLevel[] = keys.reduce((allLevels, level) => {
  return [
    ...allLevels,
    ...(levels[level].length > 0
      ? levels[level].map(grade => {
          const levelFilter: SearchLevel = `${level}|${grade}`
          return levelFilter
        })
      : [level]),
  ]
}, [] as SearchLevel[])

type Props<T extends FieldValues> = {
  screenLoading?: boolean
  form: UseFormReturn<T>
}

export default function GradeSelector<T extends FieldValues>({
  screenLoading = false,
  form: {
    formState: {errors},
    register,
  },
}: Props<T>) {
  const user = useUser()
  const schoolFromAPI = useSchool()

  const loading = screenLoading || user.isLoading || schoolFromAPI.isLoading

  const validLevels =
    user.data && user.data.grades && user.data.grades.length
      ? searchLevels.filter(level => {
          const name = level.toLowerCase()
          return user.data!.grades!.find(grade => {
            return name.includes(grade.toLowerCase())
          })
        })
      : schoolFromAPI.data && schoolFromAPI.data.levels
      ? Object.entries(levels)
          .reduce((all, data) => {
            const [academicLevel, grades] = data
            const newLevels: SearchLevel[] = grades.length
              ? grades.map(grade => {
                  const searchLevel: SearchLevel = `${
                    academicLevel as AcademicLevel
                  }|${grade as Grade}`
                  return searchLevel
                })
              : [academicLevel as AcademicLevel]
            return [...all, ...newLevels]
          }, [] as SearchLevel[])
          .filter(level => {
            const name = level.toLowerCase()
            return schoolFromAPI.data!.levels.find(current => {
              return name.includes(current.toLowerCase())
            })
          })
      : ([] as SearchLevel[])

  return (
    <div className="">
      <label className="text-sm text-slate-500" htmlFor="academicLevel">
        Academic Level
      </label>
      <select
        {...register('level' as any, {required: 'Required'})}
        disabled={loading}
        className="leading-2 block h-9 w-full border-b-2 border-slate-300 bg-transparent pl-0 text-base outline-none focus:border-blue-700">
        <option />
        {Object.entries(levels).map(([academicLevel, grades]) => {
          return grades.length > 0 ? (
            grades.map(grade => {
              const value: SearchLevel = `${
                academicLevel as AcademicLevel
              }|${grade}`
              const label = `${academicLevel} - ${grade}`
              return (
                <option
                  selected={user.data?.selectedGrade === value}
                  value={value}
                  key={value}
                  disabled={!validLevels.includes(value)}>
                  {label}
                </option>
              )
            })
          ) : (
            <option
              value={academicLevel}
              key={academicLevel}
              disabled={
                !schoolFromAPI.data?.levels.includes(
                  academicLevel as SearchLevel,
                )
              }>
              {academicLevel}
            </option>
          )
        })}
      </select>
      <div className="mt-1 h-5">
        <ErrorMessage
          className="text-xs text-red-600"
          errors={errors}
          // @ts-ignore
          name="level"
          as="div"
        />
      </div>
    </div>
  )
}
