import type {SWRConfiguration} from 'swr'
import useSWR from 'swr'
import {CefrLevel} from 'teachers-types'

import {getRelatedLevels} from '../api/itemApi'
import {Item} from '../types'

export default function useRelatedLevels(
  id: Item['id'],
  options?: SWRConfiguration,
) {
  return useSWR<Partial<Record<CefrLevel, Item>>, Error>(
    ['related-levels', id],
    () => getRelatedLevels(id),
    options,
  )
}
