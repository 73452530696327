import type {SWRConfiguration} from 'swr'
import useSWR from 'swr'
import {Component} from 'teachers-types'

import {getRelatedComponents} from '../api/itemApi'
import {Item} from '../types'

export default function useRelatedComponents(
  id: Item['id'],
  options?: SWRConfiguration,
) {
  return useSWR<Partial<Record<Component, Item>>, Error>(
    ['related-components', id],
    () => getRelatedComponents(id),
    options,
  )
}
