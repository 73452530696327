import {GENERIC_ERROR_MESSAGE} from './common'
import {SchoolLists, SentList} from '../types'
import {apiBaseUrl} from '../config'
import authorizationHeader from './authorizationHeader'

export async function getAdminLists(): Promise<SchoolLists[]> {
  return fetch(apiBaseUrl + '/admin/lists', {
    headers: authorizationHeader(),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}

export async function updateListStatus(
  id: SentList['id'],
  newStatus: SentList['status'],
): Promise<SchoolLists[]> {
  return fetch(apiBaseUrl + '/admin/lists/' + id, {
    headers: authorizationHeader({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
    body: JSON.stringify({status: newStatus}),
  }).then(res => {
    if (!res.ok) throw new Error(GENERIC_ERROR_MESSAGE)
    return res.json()
  })
}
