import {
  BuildingLibraryIcon,
  AcademicCapIcon,
  UserCircleIcon,
  UserGroupIcon,
  ChatBubbleLeftIcon,
} from '@heroicons/react/24/outline'
import {Menu, Transition} from '@headlessui/react'
import {prop, uniqBy} from 'ramda'
import * as React from 'react'
import {Link} from 'react-router-dom'

import {SchoolLists, SentList} from '../types'
import {classNames} from '../utils'
import {updateListStatus} from '../api/adminApi'
import {useToasts} from '../components/ToastsProvider'
import {useSWRConfig} from 'swr'

function LevelSection({lists}: {lists: SentList[]}) {
  const {addToast} = useToasts()
  const global = useSWRConfig()

  return (
    <section className="mt-2 ml-4 rounded-md bg-white py-1 px-4 shadow print:bg-transparent print:shadow-none">
      <ul className="divide-y">
        {lists.map(list => {
          return (
            <li
              key={list.id}
              // className="page-break-after-always"
            >
              <header
                className={classNames(
                  'relative -mx-4 grid grid-cols-[200px_1fr_1fr_1fr_1fr_min-content] items-baseline gap-4 px-4 pt-2 pb-3',
                  'hover:bg-slate-50',
                )}>
                <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-slate-600"></div>

                <div className="text-xs text-slate-600"></div>

                <div className="flex items-baseline gap-2 text-xs"></div>

                <Menu as="div" className="relative">
                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    as={React.Fragment}>
                    <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({active}) => (
                          <button
                            className={classNames(
                              active && 'bg-gray-100',
                              'flex w-full items-center gap-2 px-4 py-2 text-left text-sm disabled:cursor-not-allowed disabled:opacity-40',
                            )}
                            onClick={() => {
                              global.mutate('adminLists', () =>
                                updateListStatus(
                                  list.id,
                                  list.status === 'approved'
                                    ? 'pending approval'
                                    : 'approved',
                                ).then(res => {
                                  addToast('List updated', {
                                    variant: 'success',
                                  })
                                  return res
                                }),
                              )
                            }}>
                            {list.status === 'approved'
                              ? 'Set as Pending'
                              : 'Approve list'}
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({active}) => (
                          <button
                            className={classNames(
                              active && 'bg-gray-100',
                              'flex w-full items-center gap-2 px-4 py-2 text-left text-sm disabled:cursor-not-allowed disabled:opacity-40',
                            )}
                            disabled>
                            Archive
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </header>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition-all duration-75 ease-out"
                leaveFrom="transform opacity-100 max-h-screen"
                leaveTo="transform opacity-0 max-h-0"
                show={true}>
                <div className="flex items-baseline gap-12">
                  <div className="text-sm text-slate-600">
                    <UserCircleIcon className="relative -top-0.5 mr-1.5 inline-block w-5 align-middle text-slate-400" />
                    By {list.author.username}
                    <div className="ml-6 mr-4 -mt-1 font-mono text-[11px] text-slate-400">
                      {list.author.email}
                    </div>
                  </div>

                  <div className="text-sm text-slate-600">
                    <UserGroupIcon className="relative -top-0.5 mr-1.5 inline-block w-5 align-middle text-slate-400" />
                    {list.studentsAmount}{' '}
                    {list.studentsAmount === 1 ? 'student' : 'students'}
                  </div>

                  <div
                    className={classNames(
                      list.status === 'approved' &&
                        'border-green-400 text-green-600',
                      list.status === 'pending approval' &&
                        'border-orange-300 text-orange-500',
                      'ml-6 inline-block whitespace-nowrap rounded-md border bg-white px-4 py-1 text-sm',
                    )}>
                    {list.status === 'pending approval'
                      ? 'Pending Approval'
                      : 'Approved'}
                  </div>
                </div>

                {list.message && (
                  <div className="-mx-2 mt-2 flex items-baseline rounded-md p-2">
                    <ChatBubbleLeftIcon className="mr-1.5 w-5 shrink-0 self-start text-slate-400" />
                    <span className="text-sm text-slate-600">
                      <strong className="font-semibold text-slate-500">
                        Author message:{' '}
                      </strong>
                      {list.message}
                    </span>
                  </div>
                )}

                <div className="page-break-inside-avoid">
                  <p className="mt-4 text-sm text-slate-500">
                    {list.items.length}{' '}
                    {list.items.length === 1 ? 'book' : 'books'}
                  </p>

                  <ul className="mt-2 grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-x-6 gap-y-4 pb-4">
                    {list.items.map(item => {
                      return (
                        <li className="relative flex flex-col" key={item.id}>
                          {!!item.thumbnail && (
                            <img
                              className="block max-w-[80px] bg-slate-200 shadow-md"
                              width={210 / 4}
                              height={297 / 4}
                              src={item.thumbnail || '/Image_not_available.png'}
                              alt=""
                            />
                          )}
                          <Link
                            className="z-10 mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2 underline-offset-2 hover:underline"
                            to={'/item/' + item.id}>
                            {item.title}
                          </Link>
                          <div className="flex flex-col">
                            <div className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2">
                              Number of students:{' '}
                              <strong>
                                {list.amounts?.[item.id] || list.studentsAmount}
                              </strong>
                            </div>
                            <div className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2">
                              KEL Code: <strong>{item.kelCode}</strong>
                            </div>
                            <div className="mt-2 inline-block text-xs leading-tight text-blue-900 decoration-slate-200 decoration-2">
                              ISBN: <strong>{item.isbn}</strong>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </Transition>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

function SchoolSection({school}: {school: SchoolLists}) {
  return (
    <section className=" py-4 px-3 shadow">
      <header className="flex">
        <BuildingLibraryIcon className="relative -top-0.5 mr-2 inline-block w-6 text-slate-600" />
        <span className="grow text-xl text-slate-600">{school.name}</span>
      </header>

      <ul className="mt-4 divide-y">
        {Object.entries(school.levels).map(([level, lists]) => {
          const uniqueBooksCount = uniqBy(
            prop('id'),
            lists.map(list => list.items).flat(),
          ).length

          const hasLists = lists.length > 0
          return (
            <li className={'pb-4'} key={level}>
              <header
                className={classNames(
                  'group flex items-center gap-3 py-2',
                  'pt-2 pb-0',
                  hasLists && 'cursor-pointer',
                )}>
                <AcademicCapIcon
                  className={classNames(
                    'relative -top-0.5 ml-1 -mr-1 inline-block w-5 align-middle',
                    hasLists
                      ? 'text-slate-400 group-hover:text-blue-700'
                      : 'text-slate-300',
                  )}
                />

                <span
                  className={classNames(
                    'grow text-sm',
                    hasLists
                      ? 'font-semibold text-slate-600 group-hover:text-blue-700'
                      : 'text-slate-400',
                  )}>
                  {level.split('|').join(' - ')}
                </span>

                {hasLists ? (
                  <span className="text-xs text-slate-500">
                    {uniqueBooksCount}{' '}
                    {uniqueBooksCount === 1 ? 'book' : 'books'} in{' '}
                    {lists.length} {lists.length === 1 ? 'list' : 'lists'}
                  </span>
                ) : (
                  <span className="text-xs italic text-slate-400">
                    No lists
                  </span>
                )}
              </header>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition-all duration-75 ease-out"
                leaveFrom="transform opacity-100 max-h-screen"
                leaveTo="transform opacity-0 max-h-0"
                show={true}>
                <LevelSection lists={lists} />
              </Transition>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

type Props = {
  schools: SchoolLists[]
}

function AdminLists({schools}: Props) {
  return (
    <div className="hidden print:block">
      <ul className="mt-4 max-w-6xl space-y-4">
        {schools.map(school => {
          return (
            <li key={school.id}>
              <SchoolSection school={school} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default function AdminPrintPage({schools}: Props) {
  return <AdminLists schools={schools} />
}
