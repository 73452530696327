import {rest} from 'msw'

import {collectionFactory, getLocalItems} from './mockHelpers'
import {apiBaseUrl} from '../config'
import * as types from 'teachers-types'
import {
  ArrayFilter,
  FilterValue,
  Item,
  SearchResponse,
  SortParam,
} from '../types'
import {series} from './series'

if (!getLocalItems().length) {
  window.localStorage.setItem('items', JSON.stringify(collectionFactory()))
}

function sortItems(sort: SortParam) {
  const [sortBy, sortOrder] = sort.split('|')
  return (a: Item, b: Item) => {
    let valueA = 0
    let valueB = 0
    if (sortBy === 'edition') {
      valueA = a.edition
      valueB = b.edition
    }
    if (sortBy === 'price') {
      valueA = a.price
      valueB = b.price
    }
    return sortOrder === 'asc' ? valueA - valueB : valueB - valueA
  }
}

const searchHandler = rest.get<null, {}, SearchResponse>(
  apiBaseUrl + '/search',
  (req, res, ctx) => {
    const localItems = getLocalItems()

    const data = localItems
      .filter(v => {
        const isRecommended =
          req.url.searchParams.get('isRecommended') === 'true'
        const component = req.url.searchParams.getAll('component')
        if (!component.length) return isRecommended ? v.isRecommended : true
        return (
          req.url.searchParams.getAll('component').includes(v.component) &&
          (isRecommended ? v.isRecommended : true)
        )
      })
      .sort(
        sortItems(
          (req.url.searchParams.get('sort') || 'edition|desc') as SortParam,
        ),
      )

    const response: SearchResponse = {
      count: data.length,
      offset: parseInt(req.url.searchParams.get('offset') || '0', 10),
      limit: parseInt(req.url.searchParams.get('limit') || '20', 10),
      data,
      filters: [
        {
          type: 'boolean',
          name: 'isRecommended',
          count: data.filter(i => i.isRecommended).length,
        },
        types.components.reduce(
          (acc, name) => {
            acc.values.push({
              name,
              count: data.filter(i => i.component === name).length,
            })
            return acc
          },
          {name: 'component', values: [], type: 'array'} as ArrayFilter,
        ),
        types.subjects.reduce(
          (acc, name) => {
            acc.values.push({
              name,
              count: data.filter(i => i.subject === name).length,
            })
            return acc
          },
          {name: 'subject', values: [], type: 'array'} as ArrayFilter,
        ),
        types.languages.reduce(
          (acc, name) => {
            acc.values.push({
              name,
              count: data.filter(i => i.language === name).length,
            })
            return acc
          },
          {name: 'language', values: [], type: 'array'} as ArrayFilter,
        ),
        Object.entries(types.levels).reduce(
          (acc, [levelName, grades]) => {
            acc.values.push({
              name: levelName,
              count: data.filter(i => i.academicLevel === levelName).length,
              children: (grades as readonly types.Grade[]).reduce(
                (values, gradeName) => {
                  values.push({
                    name: gradeName,
                    count: data.filter(
                      i =>
                        i.academicLevel === levelName && i.grade === gradeName,
                    ).length,
                  })
                  return values
                },
                [] as FilterValue[],
              ),
            })
            return acc
          },
          {name: 'level', values: [], type: 'array'} as ArrayFilter,
        ),
        types.curriculums.reduce(
          (acc, name) => {
            acc.values.push({
              name,
              count: data.filter(i => i.curriculum === name).length,
            })
            return acc
          },
          {name: 'curriculum', values: [], type: 'array'} as ArrayFilter,
        ),
        types.approaches.reduce(
          (acc, name) => {
            acc.values.push({
              name,
              count: data.filter(i => i.approach === name).length,
            })
            return acc
          },
          {name: 'approach', values: [], type: 'array'} as ArrayFilter,
        ),
        types.workloads.reduce(
          (acc, name) => {
            acc.values.push({
              name,
              count: data.filter(i => i.workload === name).length,
            })
            return acc
          },
          {name: 'workload', values: [], type: 'array'} as ArrayFilter,
        ),
        types.certificates.reduce(
          (acc, name) => {
            acc.values.push({
              name,
              count: data.filter(i => i.certificate === name).length,
            })
            return acc
          },
          {
            name: 'certificate',
            values: [],
            type: 'array',
          } as ArrayFilter,
        ),
        series.reduce(
          (acc, name) => {
            acc.values.push({
              name,
              count: data.filter(i => i.serie === name).length,
            })
            return acc
          },
          {name: 'serie', values: [], type: 'array'} as ArrayFilter,
        ),
      ],
    }
    return res(ctx.status(200), ctx.json(response), ctx.delay(700))
  },
)

const forYouHandler = rest.get<null, {}, SearchResponse>(
  apiBaseUrl + 'search/for-you',
  (req, res, ctx) => {
    const localItems = getLocalItems()

    const data = localItems
      .slice(10, 30) // just get a few items
      .sort(
        sortItems(
          (req.url.searchParams.get('sort') || 'edition|desc') as SortParam,
        ),
      )

    const response: SearchResponse = {
      count: data.length,
      offset: parseInt(req.url.searchParams.get('offset') || '0', 10),
      limit: parseInt(req.url.searchParams.get('limit') || '20', 10),
      data,
      filters: [],
    }
    return res(ctx.status(200), ctx.json(response), ctx.delay(700))
  },
)

export const handlers = [searchHandler, forYouHandler]
