import {Item} from '../types'
import {classNames} from '../utils'

interface Props {
  currentComponent: string
  item: Item
}

export default function ReadersComponentsSection(props: Props) {
  return (
    <div>
      <div className="mt-6 text-xs text-slate-500">Components</div>
      <div className="mt-1 md:flex">
        <div
          className={classNames(
            'z-10 border-purple-500 bg-purple-500 text-white [text-shadow:1px_1px_1px_gray]',
            'border px-3 py-1 font-semibold uppercase [font-size:10px] [letter-spacing:1.5px] md:rounded-br-md md:rounded-tr-md',
          )}>
          {props.item.component}
        </div>
      </div>
    </div>
  )
}
