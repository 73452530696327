import {
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
  BuildingLibraryIcon,
  PaperAirplaneIcon,
  AcademicCapIcon,
  UserGroupIcon,
  ClipboardIcon,
  PencilIcon,
  TrashIcon,
  ClockIcon,
} from '@heroicons/react/24/outline'
import {useNavigate, useParams} from 'react-router-dom'
import {Menu, Transition} from '@headlessui/react'
import {useSWRConfig} from 'swr'
import * as React from 'react'

import {removeItemFromList, sendListForReview, deleteList} from '../api/listApi'
import EditListDialog from '../components/EditListDialog'
import SendListDialog from '../components/SendListDialog'
import {classNames} from '../utils'
import {Item, List, ListItem as ListItemType} from '../types'
import {useToasts} from '../components/ToastsProvider'
import BaseDialog from '../components/BaseDialog'
import ListItem from '../components/ListItem'
import Spinner from '../components/Spinner'
import useList from '../hooks/useList'
import useSchool from '../hooks/useSchool'
import EditListItemDialog from '../components/EditListItemDialog'
import useHistory from '../hooks/useHistory'
import {format} from 'date-fns'
import useUser from '../hooks/useUser'

export default function ListDetailPage() {
  const {id} = useParams<{id: List['id']}>()
  if (!id) throw new Error('list id not present in URL')

  const {addToast} = useToasts()
  const navigate = useNavigate()
  const global = useSWRConfig()
  const school = useSchool()
  const {data: user} = useUser()

  const {data: listData, error, mutate} = useList(id)
  const {data: history} = useHistory(id)

  const [action, setAction] = React.useState<
    'remove-item' | 'edit-item' | 'delete' | 'send' | 'edit' | null
  >(null)
  const [selectedItem, setSelectedItem] = React.useState<Item | null>(null)

  const [showHistory, setShowHistory] = React.useState(false)
  const [selectedHistory, setSelectedHistory] = React.useState('')

  const isLoading = !listData && !error

  const toggleShowHistory = () => {
    setShowHistory(current => {
      if (current) {
        setSelectedHistory('')
      }
      return !current
    })
  }

  const toggleSelectedHistory = (selected: string) => {
    setSelectedHistory(current => (current === selected ? '' : selected))
  }

  const data = selectedHistory
    ? history?.find(el => el.id === selectedHistory)
    : listData

  const handleRemoveItemConfirmation = React.useCallback(
    (itemId: Item['id']) => {
      if (!data) return
      const optimisticData = {
        ...data,
        items: data.items.filter(item => item.id !== itemId),
      }
      setAction(null)
      mutate(
        () =>
          removeItemFromList(itemId, id).then(res => {
            global.mutate('lists') // update count in sidebar
            addToast('Item removed', {variant: 'success'})
            return res
          }),
        {
          rollbackOnError: true,
          optimisticData,
        },
      )
    },
    [addToast, data, global, id, mutate],
  )

  const handleSendForReview = (message?: string) => {
    return mutate(() =>
      sendListForReview(id, message).then(res => {
        addToast('List sent for review', {variant: 'success'})
        global.mutate('lists') // update list status in sidebar
        setAction(null)
        return res
      }),
    )
  }

  const handleDeleteList = () => {
    navigate('/search')
    global.mutate(
      'lists',
      () =>
        deleteList(id).then(res => {
          addToast('List removed', {variant: 'success'})
          return res
        }),
      {
        rollbackOnError: true,
        optimisticData(currentData: List[]) {
          return currentData.filter(currentList => currentList.id !== id)
        },
      },
    )
  }

  const onAmountChange = (_item: Item) => {}

  const canSendForApproval =
    data?.status === 'draft' &&
    data?.items.length > 0 &&
    data.studentsAmount &&
    data.schoolHubspotId &&
    data.level

  const canRemoveItems =
    data?.status === 'draft' ||
    (user?.type === 'teacherAdmin' && data?.status === 'pending approval')

  const canDelete =
    data?.status === 'draft' ||
    (user?.type === 'teacherAdmin' && data?.status === 'pending approval')

  const canEdit =
    data?.status === 'draft' ||
    (user?.type === 'teacherAdmin' && data?.status === 'pending approval')
  return (
    <div>
      {data && (
        <>
          <header className="mt-10 mb-8 flex flex-col items-baseline justify-between gap-4 md:mt-0 md:flex-row">
            <div className="grow">
              <div className="flex grow">
                <div className="grow">
                  <h1 className="border-b-2 border-transparent text-3xl">
                    {data.name !== listData?.name && (
                      <span className="mr-1 line-through">
                        {listData?.name}
                      </span>
                    )}
                    {data.name}
                  </h1>
                </div>

                <More
                  className="block md:hidden"
                  onEdit={() => setAction('edit')}
                  canEdit={canEdit}
                  onDelete={() => setAction('delete')}
                  canDelete={canDelete}
                />
              </div>

              <div className="mt-2 flex items-baseline gap-8">
                {data.schoolHubspotId && (
                  <span className="text-sm text-slate-500">
                    <BuildingLibraryIcon className="relative -top-0.5 mr-0 inline-block w-5 align-middle text-slate-400" />{' '}
                    {school.data &&
                    school.data?.hubspotId === data.schoolHubspotId
                      ? school.data.name
                      : data.schoolHubspotId}
                  </span>
                )}

                {data.level && (
                  <span className="text-sm text-slate-500">
                    <AcademicCapIcon className="relative -top-0.5 mr-1 inline-block w-5 align-middle text-slate-400" />
                    {data.level !== listData?.level && (
                      <span className="mr-1 line-through">
                        {listData?.level?.split('|').join(' - ')}
                      </span>
                    )}
                    {data.level.split('|').join(' - ')}
                  </span>
                )}

                {data.studentsAmount && (
                  <span className="text-sm text-slate-500">
                    <UserGroupIcon className="relative -top-0.5 mr-1 inline-block w-5 align-middle text-slate-400" />
                    {data.studentsAmount !== listData?.studentsAmount && (
                      <span className="mr-1 line-through">
                        {listData?.studentsAmount}
                      </span>
                    )}
                    {data.studentsAmount}{' '}
                    {data.studentsAmount === 1 ? 'student' : 'students'}
                  </span>
                )}

                {/* add here */}
                {(history?.length ?? 0) > 0 && (
                  <button
                    className="text-sm text-slate-500"
                    onClick={toggleShowHistory}>
                    <ClockIcon className="relative -top-0.5 mr-1 inline-block w-5 align-middle text-slate-400" />
                    {history?.length}{' '}
                    {history?.length === 1 ? 'version' : 'versions'}
                  </button>
                )}

                {(!data.schoolHubspotId ||
                  !data.level ||
                  !data.studentsAmount) &&
                  !selectedHistory && (
                    <button
                      className="rounded-sm text-sm text-blue-600 underline decoration-blue-200 underline-offset-1 outline-none ring-offset-2 hover:text-blue-700 hover:decoration-blue-400 focus-visible:ring-2"
                      onClick={() => setAction('edit')}
                      type="button">
                      <PencilIcon className="relative -top-0.5 mr-1 inline-block w-4 align-middle" />
                      {!data.schoolHubspotId
                        ? 'Choose school'
                        : !data.level
                        ? 'Choose academic level'
                        : !data.studentsAmount
                        ? 'Choose students amount'
                        : 'Edit'}
                    </button>
                  )}
              </div>
            </div>

            {!selectedHistory && (
              <More
                className="hidden md:block"
                onEdit={() => setAction('edit')}
                canEdit={canEdit}
                onDelete={() => setAction('delete')}
                canDelete={canDelete}
              />
            )}

            <button
              className={classNames(
                data.status === 'approved' &&
                  'border-green-400 bg-transparent text-green-600',
                (data.status === 'pending approval' ||
                  data.status === 'archived') &&
                  'border-orange-300 bg-transparent text-orange-500',
                data.status === 'draft' &&
                  'border-transparent bg-blue-700 text-white hover:bg-blue-800 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:bg-blue-700',
                'flex items-baseline gap-2 whitespace-nowrap rounded-md border-2 px-5 py-2 text-base outline-none ring-offset-2 focus-visible:ring-2',
              )}
              disabled={!canSendForApproval || !!selectedHistory}
              onClick={() => setAction('send')}
              type="button">
              <PaperAirplaneIcon
                className={classNames(
                  data.status === 'approved' && 'text-green-500',
                  data.status === 'draft' && 'text-white',
                  (data.status === 'pending approval' ||
                    data.status === 'archived') &&
                    'text-orange-400',
                  '-ml-1 w-4 shrink-0 -rotate-45',
                )}
              />
              {data.status === 'draft'
                ? 'Send for review'
                : data.status === 'pending approval' ||
                  data.status === 'archived'
                ? 'Pending Approval'
                : data.status === 'kelApproved'
                ? 'Approved by Kel'
                : 'Approved'}
            </button>
          </header>

          {showHistory && (
            <div className="mb-4">
              <ul className="flex flex-wrap gap-y-3">
                {history?.map(el => (
                  <li
                    key={el.id}
                    className={`-top-0.5 mr-1 inline rounded-md border ${
                      el.id === selectedHistory
                        ? 'border-red-400'
                        : 'border-blue-400'
                    } px-1 py-0.5 font-semibold uppercase text-blue-400 [font-size:11px] [letter-spacing:1px]`}>
                    <button onClick={() => toggleSelectedHistory(el.id)}>
                      {format(new Date(el.updatedAt!), 'dd/MM/yyyy HH:mm')}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {!!selectedHistory && data.updatedBy?.email && (
            <p className="mb-2 text-sm text-slate-500">
              Updated by: {data.updatedBy?.email}
            </p>
          )}
          {data.items.length ? (
            <>
              <p className="text-sm text-slate-500">
                {data.items.length !== listData?.items.length && (
                  <span className="mr-1 line-through">
                    {listData?.items.length}
                  </span>
                )}
                {data.items.length} {data.items.length === 1 ? 'book' : 'books'}
              </p>
              <ul className="divide-y">
                {data.items.map(item => {
                  const listItem: ListItemType = {
                    ...item,
                    amount: data.amounts?.[item.id] || data.studentsAmount,
                  }
                  const previousData = listData?.items.find(
                    el => el.id === item.id,
                  )
                  const previousItem = previousData
                    ? {
                        ...previousData,
                        amount:
                          listData?.amounts?.[previousData.id] ||
                          listData?.studentsAmount,
                      }
                    : null
                  return (
                    <li key={listItem.id} className="py-4 px-0">
                      <ListItem
                        item={listItem}
                        previousItem={previousItem}
                        listId={data.id}
                        toolbar={
                          <>
                            {canEdit && !selectedHistory && (
                              <button
                                className="rounded-full p-2 text-slate-400 outline-none hover:bg-blue-100 hover:text-blue-700 focus-visible:ring-2"
                                type="button"
                                onClick={() => {
                                  setSelectedItem(listItem)
                                  setAction('edit-item')
                                }}>
                                <PencilIcon className="w-4" />
                              </button>
                            )}
                            {canRemoveItems && !selectedHistory && (
                              <button
                                className="rounded-full p-2 text-slate-400 outline-none hover:bg-blue-100 hover:text-blue-700 focus-visible:ring-2"
                                type="button"
                                onClick={() => {
                                  setSelectedItem(listItem)
                                  setAction('remove-item')
                                }}>
                                <TrashIcon className="w-4" color="red" />
                              </button>
                            )}
                          </>
                        }
                        onAmountChange={onAmountChange}
                      />
                    </li>
                  )
                })}
              </ul>
            </>
          ) : (
            <div className="mx-auto my-24 text-center">
              <ClipboardIcon className="inline-block w-12 text-slate-400" />
              <div className="mt-2 text-2xl text-slate-600">
                This list is currently empty
              </div>
              <div className="mt-2 text-sm text-slate-500">
                How about adding some books?
              </div>
            </div>
          )}
        </>
      )}

      {isLoading && <Spinner className="mx-auto mt-[calc(50vh-300px)]" />}

      {error && !data && (
        <div className="mx-auto my-24 text-center">
          <ExclamationCircleIcon className="inline-block w-12 text-red-400" />
          <div className="mt-2 text-lg text-slate-500">{error.message}</div>
        </div>
      )}

      <BaseDialog
        noCloseButton
        onClose={() => setAction(null)}
        isOpen={action === 'delete'}
        title="Delete list"
        size="sm">
        <div className="mt-4">
          <p className="text-sm text-slate-500">
            Are you sure you that you want to delete this list?
          </p>
          <p className="mt-1 text-sm font-semibold text-slate-500">
            This action cannot be undone.
          </p>

          <div className="mt-6 flex gap-4">
            <button
              className="rounded-md bg-blue-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-blue-400 ring-offset-2 hover:bg-blue-600 focus-visible:ring-2 disabled:bg-blue-300"
              onClick={handleDeleteList}>
              Delete
            </button>
            <button
              className="rounded-md px-4 py-3 text-sm font-semibold text-blue-500 underline outline-none ring-blue-400 ring-offset-2 hover:text-blue-600 focus-visible:ring-2 disabled:text-blue-300"
              onClick={() => setAction(null)}>
              Cancel
            </button>
          </div>
        </div>
      </BaseDialog>

      <BaseDialog
        noCloseButton
        afterLeave={() => setSelectedItem(null)}
        onClose={() => setAction(null)}
        isOpen={action === 'remove-item'}
        title="Remove Item"
        size="sm">
        <div className="mt-4">
          <p className="text-sm text-slate-500">
            Are you sure you that you want to remove{' '}
            <strong className="text-slate-600">{selectedItem?.title}</strong>{' '}
            from this list?
          </p>

          <div className="mt-6 flex gap-4">
            <button
              className="rounded-md bg-blue-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-blue-400 ring-offset-2 hover:bg-blue-600 focus-visible:ring-2 disabled:bg-blue-300"
              onClick={() => {
                if (selectedItem) handleRemoveItemConfirmation(selectedItem.id)
              }}>
              Remove
            </button>
            <button
              className="rounded-md px-4 py-3 text-sm font-semibold text-blue-500 underline outline-none ring-blue-400 ring-offset-2 hover:text-blue-600 focus-visible:ring-2 disabled:text-blue-300"
              onClick={() => setAction(null)}>
              Cancel
            </button>
          </div>
        </div>
      </BaseDialog>

      <SendListDialog
        onSubmit={handleSendForReview}
        onClose={() => setAction(null)}
        isOpen={action === 'send'}
      />

      {data && (
        <EditListDialog
          onSubmit={handleSendForReview}
          onClose={() => setAction(null)}
          isOpen={action === 'edit'}
          listId={data.id}
        />
      )}

      {data && selectedItem && action === 'edit-item' && (
        <EditListItemDialog
          onSubmit={async () => ({} as any)}
          onClose={() => setAction(null)}
          isOpen={true}
          listId={data.id}
          listItemId={selectedItem.id}
        />
      )}
    </div>
  )
}

type MoreProps = {
  onEdit: () => any
  onDelete: () => any
  canEdit: boolean
  canDelete: boolean
  className?: string
}

function More({canEdit, onEdit, canDelete, onDelete, className}: MoreProps) {
  return (
    <Menu as="div" className={`relative ${className || ''}`}>
      <Menu.Button
        className="shrink-0 rounded-full p-2 text-slate-400 outline-none hover:bg-blue-100 hover:text-blue-700 focus-visible:ring-2"
        aria-label="List menu">
        <EllipsisHorizontalIcon className="h-6 w-6 text-slate-400" />
      </Menu.Button>
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        as={React.Fragment}>
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({active}) => (
              <button
                className={classNames(
                  active && 'bg-gray-100',
                  'flex w-full items-center gap-2 px-4 py-2 text-left text-sm disabled:cursor-not-allowed disabled:opacity-40',
                )}
                onClick={onEdit}
                disabled={!canEdit}>
                <PencilIcon className="w-4 text-slate-400" />
                Edit details
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({active}) => (
              <button
                className={classNames(
                  active && 'bg-gray-100',
                  'flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-red-600 disabled:cursor-not-allowed disabled:opacity-40',
                )}
                disabled={!canDelete}
                onClick={onDelete}>
                <TrashIcon className="w-4" />
                Delete
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
