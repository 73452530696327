import {SubmitHandler, useForm} from 'react-hook-form'
import * as React from 'react'

import BaseDialog, {Props as BaseDialogProps} from './BaseDialog'
import {List} from '../types'
import Spinner from './Spinner'

interface Props {
  onSubmit(message?: string): Promise<List | undefined>
  onClose: BaseDialogProps['onClose']
  isOpen: BaseDialogProps['isOpen']
}

type FormData = {message?: string}

export default function SendListDialog(props: Props) {
  const [isPending, setIsPending] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const {handleSubmit, register} = useForm<FormData>()

  const send: SubmitHandler<FormData> = ({message}) => {
    setIsPending(true)
    setError(null)
    props
      .onSubmit(message)
      .catch(err => setError(err.message))
      .finally(() => setIsPending(false))
  }

  return (
    <BaseDialog
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Send list for review"
      size="lg">
      <form className="mt-4" onSubmit={handleSubmit(send)}>
        <label className="mt-4 flex items-baseline justify-between gap-4">
          <span className="text-base font-semibold text-slate-500">
            Message
          </span>
          <span className="text-xs font-semibold uppercase text-slate-400">
            optional
          </span>
        </label>
        <textarea
          className="block w-full resize-y rounded-md border border-slate-300 bg-slate-50 px-1 py-1 outline-none focus-visible:border-transparent focus-visible:ring-2 disabled:opacity-70"
          autoFocus
          disabled={isPending}
          rows={5}
          {...register('message', {setValueAs: v => v.trim()})}
        />

        {error && <p className="mt-4 -mb-1 text-base text-red-600">{error}</p>}

        <div className="mt-6 flex gap-4">
          <button
            disabled={isPending}
            className="flex items-center gap-2 rounded-md bg-blue-500 px-6 py-3 text-sm font-semibold text-white outline-none ring-blue-400 ring-offset-2 hover:bg-blue-600 focus-visible:ring-2 disabled:bg-blue-300"
            type="submit">
            {isPending && <Spinner reverse size="small" className="-ml-3" />}
            {isPending ? 'Sending...' : 'Send'}
          </button>
          <button
            className="rounded-md px-4 py-3 text-sm font-semibold text-blue-500 underline outline-none ring-blue-400 ring-offset-2 hover:text-blue-600 focus-visible:ring-2 disabled:text-blue-300"
            disabled={isPending}
            onClick={props.onClose}
            type="button">
            Cancel
          </button>
        </div>
      </form>
    </BaseDialog>
  )
}
