import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/outline'
import {useSearchParams} from 'react-router-dom'
import {Transition} from '@headlessui/react'
import * as React from 'react'

import {ArrayFilter, FilterValue} from '../types'
import {classNames} from '../utils'
import useSchool from '../hooks/useSchool'
import {isValidGrade} from '../api/searchApi'

export interface Props {
  onClear(group: string): void
  onToggle(group: string, value: string, isChecked: boolean): void
  className?: React.HTMLAttributes<HTMLDivElement>['className']
  subitems?: Record<string, readonly string[]>
  urlParam: string
  showMax?: number
  title: string
  items: ArrayFilter['values']
}

function FilterItem({
  className,
  children,
  ...otherProps
}: React.InputHTMLAttributes<HTMLInputElement> & {
  className?: React.HTMLAttributes<HTMLDivElement>['className']
  children: React.ReactNode
}) {
  return (
    <label
      className={classNames(
        className,
        '-my-1 flex cursor-pointer items-baseline gap-2 rounded-md px-2 pt-2 pb-1 hover:bg-blue-50',
      )}>
      <input
        className="inline-block h-4 w-4 shrink-0 cursor-pointer text-blue-500 accent-current outline-none ring-offset-2 focus-visible:ring-2"
        type="checkbox"
        {...otherProps}
      />{' '}
      <div className="relative -top-[2.5px]">{children}</div>
    </label>
  )
}

export default function ArrayFilterControl(props: Props) {
  const [searchParams] = useSearchParams()
  const currentParams = searchParams.getAll(props.urlParam)
  const hasCheckedItems = currentParams.some(
    v => v && props.items.some(v1 => v1.name === v.split('|')[0]),
  )
  const showMax = props.showMax ?? 5
  const hiddenItemsCount = props.items.filter(
    (value, i) => i >= showMax && !currentParams.includes(value.name),
  ).length

  const [isExpanded, setIsExpanded] = React.useState(hasCheckedItems)
  const [viewMore, setViewMore] = React.useState(false)

  const validGrade = useValidGrade()

  return (
    <section className={props.className}>
      <header className="relative flex items-center justify-between gap-2 py-1 hover:text-blue-700">
        <span className="ml-2 text-sm font-semibold capitalize">
          {props.title}
        </span>
        {hasCheckedItems ? (
          <button
            className="rounded-md p-2 text-xs text-slate-500 underline decoration-slate-300 underline-offset-1 outline-none focus-visible:ring-2"
            onClick={() => props.onClear(props.urlParam)}
            type="button">
            Clear
          </button>
        ) : (
          <button
            className="rounded-full p-2 text-slate-600 outline-none before:absolute before:inset-0 before:content-[''] hover:text-blue-600 focus-visible:ring-2"
            onClick={() => setIsExpanded(v => !v)}
            type="button">
            {isExpanded ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            )}
          </button>
        )}
      </header>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition-all duration-75 ease-out"
        leaveFrom="transform opacity-100 max-h-screen"
        leaveTo="transform opacity-0 max-h-0"
        show={isExpanded}>
        <div className="mb-4 ">
          <ul>
            {props.items.map((item, i) => {
              const isChecked = currentParams.includes(item.name)

              // omit rendering unchecked items past a certain max
              if (i >= showMax && !viewMore && !isChecked) return null

              const subitems = item.children || []
              const showSubitems = subitems.length > 0 && isChecked

              return (
                <li key={item.name}>
                  <FilterItem
                    className="text-sm"
                    onChange={e =>
                      props.onToggle(
                        props.urlParam,
                        item.name,
                        e.target.checked,
                      )
                    }
                    checked={isChecked}
                    value={item.name}
                    name={props.urlParam}>
                    {item.name}{' '}
                    {/* <span className="ml-1 text-xs italic text-slate-400">
                      ({item.count})
                    </span> */}
                  </FilterItem>

                  {showSubitems && (
                    <ul className="ml-6 mb-3">
                      {subitems.filter(validGrade).map(subitem => {
                        // create a value for subitems adding the parent value
                        const value = item.name + '|' + subitem.name
                        return (
                          <li key={value}>
                            <FilterItem
                              className="text-xs"
                              onChange={e => {
                                props.onToggle(
                                  props.urlParam,
                                  value,
                                  e.target.checked,
                                )
                              }}
                              checked={currentParams.includes(value)}
                              value={value}
                              name={props.urlParam}>
                              {subitem.name}{' '}
                              {/* <span className="ml-1 text-xs italic text-slate-400">
                                ({subitem.count})
                              </span> */}
                            </FilterItem>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>

          {hiddenItemsCount > 0 && !viewMore && (
            <button
              className="ml-9 rounded-sm p-1 text-xs text-blue-600 underline outline-none focus-visible:ring-2"
              onClick={() => setViewMore(true)}
              type="button">
              View {hiddenItemsCount} more
            </button>
          )}
        </div>
      </Transition>
    </section>
  )
}

function useValidGrade() {
  const {data: school} = useSchool()

  const province = school?.province

  const isValid = React.useCallback(
    (value: FilterValue) => {
      return isValidGrade(value.name, province)
    },
    [province],
  )

  return isValid
}
